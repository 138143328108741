
.ui.menu.headerBar{
    width:100%;
    height:62px;
    background:#fff!important;
    box-shadow:0 2px 5px rgba(0,0,0,0.2)!important;
    margin:0!important;
    z-index:3;
    position:fixed;
    top:0;
    left:0;
}

.ui.menu.headerBar .item:first-child{
    padding:0;
    margin-left:20px!important;
}

.ui.menu.headerBar .item:first-child img{
    width:73px;
}

.ui.menu.headerBar .item{
    margin:0 15px !important;
}

.ui.menu.headerBar .item{
    background:none!important;
}

.ui.menu.headerBar .item a{
    font:500 14px/9px 'Rubik', sans-serif;
    text-transform:uppercase;
    color:#000;
    float:left;
}

.ui.menu.headerBar .active.item a,
.ui.menu.headerBar .item a:hover{
    color:#f15944;
}

.ui.menu.headerBar .right.menu{
    align-items:center;
}

.ui.menu.headerBar .right.menu .item{
    padding:5px;
    background:none!important;
    margin:0 12px!important;
}

.ui.menu.headerBar .right.menu .item img{
    width:auto;
}

.ui.menu.headerBar .right.menu .item>i.icon{
    font-size:20px;
    color:#727aff;
    margin:0;
}
.ui.menu.headerBar .right.menu a:hover{
    opacity:0.8;
}

.headerAvatar {
    margin-left:20px;
}

.displayPhoto{
    font:400 15px 'Rubik',sans-serif;
    color:#616F90!important;
}




@media (max-width:768px){
    .ui.menu.headerBar .item{
        margin:0!important;
    }

    .ui.menu.headerBar .right.menu .item{
        margin:0 5px!important;
    }
}
