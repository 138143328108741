@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700&display=swap');

.navbuttons a{ float:left; margin:0 14px 0 0;}
.recoveryPara{
	width:100%;
	padding:20px;
	background:rgba(255,255,255,0.8);
	font:400 14px/24px 'Rubik', sans-serif;
	float:Left;
	background:#fff;
	position: absolute;;
	left:0;
	top:5px;
}

.navbar-nav{ margin-left:auto; margin-right:30px;}
.navbar-nav .nav-item{ float:left; margin:0 20px;}
.navbar-nav .nav-item a{ float:left; font:400 16px 'Rubik', sans-serif; color:#000 !important; margin:0; padding:0 !important;}
.navbar-nav .nav-item .dropdown-menu{ padding:5px 0;}
.navbar-nav .nav-item .dropdown-menu .dropdown-item,
.navbar-nav .nav-item .dropdown-menu .dropdown-item:active,
.navbar-nav .nav-item .dropdown-menu .dropdown-item:focus{ width:100%; float:left; padding:10px 10px !important; font:400 14px 'Rubik', sans-serif; color:#333 !important; background:none !important;}
.navbar-nav .nav-item .dropdown-menu .dropdown-item:hover{ background:#F4F4F4 !important; color:#3b6ffd !important;}
.navbar-nav .nav-item .dropdown-toggle::after{ border-top-width:.4em; position:relative; top:3px;}
.navbar-nav .nav-item a:hover,
.navbar-nav .active a{ color:#3b6ffd !important;}
.navbar-nav .active a{ font-weight:500;}

.navbar-toggler{ position:absolute; right:15px; top:-4px;}
.navbar-toggler:before{  content:''; width:56px; height:14px; position:absolute; right:-1px; top:36px; z-index:3; display:none;}
.navbar-toggler:focus:before{ display:block;}

.btn-loading{ background: url(./media/loadbtn.gif) no-repeat 0px 45px #3b6ffd; border:none; box-shadow:none; pointer-events: none;}
.navna2{ margin-right:auto; margin-left:30px;}

/*Footer 2*/
.footer2{ width:100%; float:left; background:#fff; padding:40px 0 20px;}
.infooter{ width:945px; max-width:100%; float:right; position:relative;}
.logocover{ position:absolute; top:-16px; left:-180px;}
.logofooter{ display:inline-block;}
.foocover{ width:100%; float:left; margin:0 0 25px;}
.foocover h3{ width:100%; float:left; font:500 13px 'Rubik', sans-serif; color:#000; margin:0 0 10px;}
.foocover ul{ width:100%; float:left; margin:0; padding:0; list-style:none;}
.foocover ul li{ width:100%; float:left; margin:0 0 10px; font:400 13px 'Rubik', sans-serif; color:#000;}
.foocover ul li a{font:400 13px 'Rubik', sans-serif; color:#000;}
.foocover ul li a:hover{ color:#3b6ffd; text-decoration:underline;}
.foocover p{ width:100%; float:left; margin:0 0 10px; font:400 13px 'Rubik', sans-serif; color:#7a7a7a;}
.foocover p a{ color:#7a7a7a;}
.foocover p a:hover{ color:#000; text-decoration:underline;}
.foocover .lplink{ font:500 13px 'Rubik', sans-serif; text-decoration:none; color:#3b6ffd; background-position:99% 3px;}


.copyright2{ width:100%; float:left; font:400 13px 'Rubik', sans-serif; color:#222; text-align:center; margin:0;}
.btn-hub2{
    margin:0 10px 0 0 !important;
}


@media (max-width:1280px){
	.navbar-nav .nav-item{ margin:0 6px !important;}
}
@media (max-width:1180px){
	.btn-hub{ padding:0 10px !important;}
	.btn-hub2{ margin:0 !important;}
	.navna2{ margin-left:10px !important;}
	.navbar-nav .nav-item a{ font-size:14px !important; overflow:hidden; white-space:nowrap; text-overflow:ellipsis;}
	.navbuttons a{ margin:0 0 0 5px !important;}
}

@media (max-width:1050px){
}

@media (max-width:767px){
}


/***** Hub new *****/


.section-login {
	width:100%;
	float:left;
    padding: 43px 0 80px
}

.section-login .container {
    width: 990px;
    text-align: center;
    padding: 0 15px
}

.default-login, .form-login, .p-login, .p-login-field {
    width:350px;
    float: left
}


.login-cover {
    width: 350px;
    max-width: 100%;
    display: inline-block
}

.default-login {
    margin: 0 0 20px
}



.signup-cover{
	width:720px;
}

.sign-row{
	width:100%;
	float:left;
	display:flex;
	justify-content:space-between;
	flex-wrap:wrap;
}

.sign-row .default-login{ width:350px;}

.checkbox-contact input[type=checkbox] {
    position: absolute;
    opacity: 0;
    right: 0;
    top: 0;
    z-index: 0
}

.checkbox-contact input[type="checkbox"] + label::before
{
    content: "";
    width: 17px;
    height: 17px;
    margin: 1px 10px 0 0;
	background: url(./media/input-checkbox-tick.png) no-repeat center #fff;
	background-position:center !important;
    border: solid 1px #ccc;
    border-radius: 3px;
    cursor: pointer;
    float: left;
}

.checkbox-contact.invalid input[type="checkbox"] + label::before
{
    background-color: #ffdddd;

}


.checkbox-contact input[type=checkbox]:hover+label::before {
    border-color: #41c287
}

.checkbox-contact input[type=checkbox]:checked+label::before {
    background-color: #41c287;
	background-position:center !important;
    border-color: #41c287
}

.checkbox-contact {
    float: left;
    position: relative;
    font: 400 15px/20px Rubik, sans-serif
}

.checkbox-contact label {
    flex: 1;
	position: relative;
	display:initial;
	margin:0;
}

.checkCover{
	width: 100%;
	float: left;
    display: flex;
    align-items: flex-start;
	margin: 0 0 15px;
	text-align:left;
}

.check input[type=checkbox]+label::before {
    margin-top: 2px
}

.checkCover p {
	font: 400 15px/20px Rubik, sans-serif;
	padding-left:30px;
}

.check p a {
    color: #007aff;
    margin: 0 0 0 3px
}

.check p a:hover {
    text-decoration: underline
}

/** Above will be deleted later **/

/*** Final new ***/

/*** header login ***/

.headerLogin{
    width:100%;
    height:60px;
    box-shadow:0 1px 4px rgba(0,0,0,.1);
    position:fixed;
    top:0;
    left:0;
    display:flex;
    align-items:center;
}
.headerLogin .container{
    width: 1265px;
    max-width: 100%;
    margin:0 auto;
    padding:0 15px;
}

h1.ui.header.login, .para-login, .loginDetail{
    width:100%;
    float:left;
    font-family:"Rubik",sans-serif!important;
}

.section.add-login{
    min-height:calc(100vh - 108px);
    padding:30px 0 0;
    text-align:center;
    font-family:'Rubik',sans-serif!important;
}
.add-login .container{
    width:100%;
    max-width:600px;
    margin:0 auto;
    
}

.add-recovery .container{
    max-width:960px;
}

    .add-recovery .loginDetail{
        margin-top:80px;
    }

h1.ui.header.login{
    font-size:36px;
    font-weight:300;
    color:#000;
    margin:0 0 10px;
}

.loginDetail{
    font:300 19px/34px 'Rubik',sans-serif;
    margin:0 0 20px;
}
    .loginDetail strong{
        font-weight:500;
    }

.login-wrap{
    width:100%;
    max-width:350px;
    margin:0 auto!important;
}

.login-wrap .inputForm{
    margin:0 0 20px;
    text-align:left;
}

.login-wrap .inputForm > b{
    width:100%; float:left;
    font-size:14px;
    font-weight:400;
    color:#000;
    margin:0 0 15px;
}

.login-wrap .inputForm .ui.input > input{
    height:50px;
    padding:0 15px;
    border-radius:4px;
    border:solid 1px #b7b7b7;
    background: #fff;
    font-size:13px;
	color:#000;
}

.login-wrap .inputForm .ui.input > input.forgot{
    color:#959595;
}
.login-wrap .inputForm .ui.input > input.invalid{
    background-color: #ffdddd;
}
.login-wrap .inputForm .ui.input > input:focus {
    border-color:#16cd97;
}

.reset-wrap{
    margin-top:30px;
    clear: both;
}
.reset-wrap .password-requirements{
    margin:0 0 30px!important;
}
.reset-wrap .para-login{
    text-align:center;
}

.para-login{
    color:#000;
    text-align:right;
    margin-bottom:20px;
}
.para-login2{
    text-align:center;
}

.para-login a {
    color:#007aff
}

.para-login a:hover {
    text-decoration:underline
}

.password-requirements{
    float:left;
    width:100%;
    list-style: none;
    text-align: left;
    padding: 0;
    margin:0 0 20px!important;
}

.password-requirements li{
    font-size:14px;
    font-weight:400;
    margin:0 0 5px;
}

.invalidPassword {
    color: black;
    width:100%;
    float:left;
    margin:10px 0;
}

.validPassword {
    color: silver
}

.login-action{
    width:100%; float:left;
    display:flex;
    justify-content:center;
    margin:0 0 20px;
}
.ui.button.loginButton{
    width:100%;
    border:none!important;
    border-radius:4px!important;
    min-width:240px;
    margin:0;
    height:50px;
    font:500 18px 'Rubik',sans-serif!important;
    background:#40c298!important;
    color:#fff!important;
    justify-content:center;
    text-decoration:none!important;
}
.ui.button.loginButton:hover{
    box-shadow:inset 0 0 40px 0 rgba(0,0,0,0.2)!important;
}

.ui.button.loginSecondaryButton{
    width:100%!important;
    float:left;
    height:50px;
    border:solid 1px #b7b7b7!important;
    border-radius:4px!important;
    display:flex;
    align-items:center;
    justify-content:center;
    font:600 15px 'Rubik',sans-serif!important;
    color:#717171!important;
}

.ui.button.loginButton.disabled{
    background:#7a7a7a!important;
    opacity:0.5;
    pointer-events:none;
}

.footer{ 
    width:100%; float:left;
    padding:15px 15px;
}

.footer p{ 
    width:100%; float:left;
    font:400 13px 'Rubik', sans-serif!important; 
    text-align:center; 
    color:#222!important;
}

.recoveryCover{
    width:900px;
    margin:0 0 0 -150px;
}
.recoveryCover .loginDetail{
    margin-top:80px;
}

@media (max-width:950px){
    .recoveryCover{
        width:100%;
        margin:0 auto;
        max-width:100%;
    }
}