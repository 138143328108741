.ui.uploadModal>.header,
.ui.uploadModal>.content,
.ui.uploadModal>.actions{
    width:100%; float:left;
    padding:0!important;
}

.ui.uploadModal .ui.button:before, 
.ui.uploadModal .ui.button:after{
    background:#000;
    width:1.5px;
}

.ui.uploadModal>.actions{
    padding:0 0 30px!important;
}

.ui.uploadModal>.actions .ui.button:first-child{
    margin-left:0;
}
.ui.uploadModal>.actions .ui.button{
    margin-bottom:0;
}

/*** remove above after reSetup ***/

.aligner{
    width:100%; float:left;
}

.ui.modal.uploadModal{
    width:100%;
    max-width:616px!important;
    height:auto;
    top:100px!important;
    border-radius:2px!important;
    border:solid 1px #d3d2de!important;
    box-shadow:0 0 20px rgba(0, 0, 0, 0.3)!important;
    padding:0 40px!important;
    font-family:'Rubik',sans-serif!important;
}

.ui.modal.crop-image-modal{
    width:774px;
    border:none!important;
    padding:0!important;
}

.ui.crop-image-modal .aligner{
    padding:0 40px;
}
.ui.crop-image-modal .aligner>.header{
    padding: 0 0 5px;
    font: 400 18px "Rubik",sans-serif!important;
    border-bottom: 1px solid #d3d2de;
    color: #000;
    margin:30px 0 15px;
}
.ui.modal.crop-image-modal .content{
    padding:0;
}

.ui.modal.crop-image-modal .actions{
    padding:0 40px 29px!important;
    display:flex;
    align-items:center;
    border:none;
}
.ui.modal.crop-image-modal .actions .ui.button{
    margin-bottom:0;
}

.ui.modal.crop-image-modal .actions .dividerLine{
    margin:0 10px;
    height:23px;
}

.ui.modal.crop-image-modal .ui.modal>.close{
    top:8px;
}

.ui.modal.crop-image-modal .ui.secondary.button{
    font:500 15px 'Rubik',sans-serif;
    color:#727aff!important;
    margin:0;
}

.ui.uploadModal .aligner > .header,
.ui.modal.deleteImg .aligner > .header{
    margin:36px 0 15px;
    padding:0 0 5px;
    font:400 18px 'Rubik', sans-serif!important;
    color:#000;
    border-bottom:solid 1px #d3d2de;
    display:flex;
    align-items:center;
}

.ui.modal.uploadModal > .actions .ui.button,
.ui.modal.crop-image-modal .actions .ui.button,
.ui.modal.deleteImg .actions .ui.button{
    width:auto;
    min-width:100px;
    min-height:36px;
    border-radius:4px;
    font:500 13px 'Rubik',sans-serif!important;
    padding:0 18px;
    margin:0;
    display:flex;
    align-items:center;
    justify-content:center;
}

.ui.modal.crop-image-modal .actions .ui.button{
    font-size:15px!important;
}

.ui.modal.uploadModal > .actions > .ui.button:last-child,
.ui.modal.crop-image-modal .actions .ui.button:last-child,
.ui.modal.deleteImg .actions .ui.button:last-child{
    background:#F15944!important;
    color:#fff;
    margin-left:auto;
    font-size:13px!important;
}
.ui.modal.crop-image-modal .actions > div:nth-child(3){
    margin-right:0;
}

.ui.modal.uploadModal > .ui.button:first-child,
.ui.modal.crop-image-modal > .ui.button:first-child,
.ui.modal.deleteImg > .ui.button:first-child{
    padding:0;
    background:none;
}
.ui.modal .para{
    color:#000;
}

.uploadTab{
    width:100%; float:left;
    padding:0;
    margin:12px 0 0;
}

.uploadTab .ui.secondary.pointing.menu{
    border-bottom:solid 1px #d3d2de!important;
    margin:0!important;
}
.uploadTab .ui.secondary.pointing.menu .item {
    font: 400 15px 'Rubik', sans-serif!important;
    color: #000!important;
    padding: 7px 0;
    border: none;
    margin: 0 28px -1px 0;
}

.uploadTab .ui.secondary.pointing.menu .active.item {
    box-shadow: inset 0 -3px 0 0 #727aff;
}

.uploadTab >.ui.segment {
    width: 100%;
    min-height:248px;
    float: left;
    padding: 14px 0 0!important;
    margin: 0!important;
    border: none;
    border-radius: 0;
}

.ui.placeholder.brandUpload {
    border-radius: 2px;
    border: dashed 1.5px #D3D2DE;
    background: #F3F6FB;
    box-shadow: none;
    height: 234px;
    min-height: 0;
}

.ui.placeholder.brandUpload .header {
    font: 500 18px 'Rubik', sans-serif;
    color: #667581;
}

.ui.placeholder.brandUpload .header .sub.header {
    font: 400 15px 'Rubik', sans-serif;
    margin: 20px 0 10px;
}

.ui.placeholder.brandUpload .ui.basic.large.button {
    min-width:120px;
    min-height:45px;
    font:400 15px 'Rubik',sans-serif;
    border-radius:3px;
    border:solid 1px #E75330!important;
    color:#E75330!important;
    box-shadow:none!important;
}

.ui.input.uploadUrl {
    width: 100%;
}

.ui.input.uploadUrl>input {
    background: #f8f7fa;
    border: solid 1px #b8b5ce!important;
    border-radius: 2px;
    height: 47px;
    padding: 0 18px;
    font: 300 15px 'Rubik', sans-serif;
    color: #636364;
}

.ui.input.uploadUrl>input:focus {
    border: solid 1px #727aff!important;
}

.ui.uploadModal > .actions {
    width: 100%;
    float: left;
    border: none;
    background: none;
    margin: 30px 0 0;
}

.ui.uploadModal > .actions .ui.button{
    float:right;
    margin-right:0;
}

.label-crop{
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
    margin: 0 0 32px;
    padding: 0;
}

.label-crop > span {
    background: #ebebeb;
    padding: 0 12px;
    height: 34px;
    font: 400 13px/34px "Rubik",sans-serif;
    color: #000;
    flex: 1 1;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.label-crop .ui.secondary.button{
    margin:0 3px 0 30px!important;
    padding:0;
    display:flex;
    align-items:center;
    font:400 15px 'Rubik',sans-serif!important;
}
.label-crop .ui.button img{
    max-width:17px;
}

.crop-bg {
    width: 100%;
    float: left;
    min-height: 256px;
    background: #ccc;
    margin: 0 0 30px;
    position: relative;
}

.cropper-bg{
    width:774px!important;
}

.crop-bg>div:first-child{
    width: 100%!important;
}

.ui.modal.deleteImg{
    width:400px;
    padding:0 40px 30px;
    border-radius:0;
}
.ui.modal.deleteImg .aligner,
.ui.modal.deleteImg .content{
    padding:0;
}

.ui.modal.deleteImg .pModal{
    margin-bottom:25px!important;
    letter-spacing:-0.4px;
}

.ui.modal.deleteImg .actions{
    width:100%; float:left;
    padding:0;
    border:none;
    display:flex;
    justify-content:space-between!important;
}
.ui.modal.deleteImg .actions .ui.button{
    margin:0!important;
}
.ui.modal.deleteImg .actions .ui.secondary.button{
    border: none!important;
    background: #e0e1e2!important;
    box-shadow: none!important;
}
.ui.modal.deleteImg .actions .ui.button:last-child{
    min-width:80px;
}

@media (max-width:768px){
    .ui.modal.crop-image-modal > .content{
        padding:0!important;
    }
}
