.userLayout{
    padding:30px 0 0;
}
.userLayout .sectionSettings{
    padding:0;
}
.userLayout h2.ui.header{
    padding-left:75px;
    margin:0 0 32px;
}
.userLayout .sectionSettings > img{
    left:35px;
}

.userWrapper{
    max-width:100%;
}
.userTab .ui.secondary.menu{
    padding-left:35px;
    position: relative;
}
.userTab > .ui.secondary.menu:before {
    content: '';
    width: 35px;
    height: 1px;
    background: #fff;
    position: absolute;
    bottom: -1px;
    left: 0;
}
.userTab > .ui.segment{
    width:100%; float:left;
    background:#F3F6FB;
    /* background:#000; */
    padding:30px 35px!important;
}

.adminTag{
    background:#667581;
    height:19px;
    padding:0 5px;
    margin:0 0 0 10px;
    font:400 10px/19px 'Rubik',sans-serif;
    color:#fff;
    border-radius:2px;
    display:inline-block;
    position:relative;
    top:-2px;
}

.userTable .tableBoxColumn:first-child{
    display:inline-block;
}
.emailUser{
    font:400 15px 'Rubik',sans-serif;
    color:#667581;
}
.tableBoxUser .tableBoxColumn:last-child{
    align-items:center;
}

.userTab .defaultWrap.flexible .ui.orange.button{
    margin-left:auto;
}

.limitedGroupCover{
    margin:20px 0 15px;   
}
.limitedGroupCover .ui.dropdownGroup{
    max-width:225px;
}

.limitedGroupCover .ui.dropdownGroup >.menu{max-height:200px; overflow-y:auto;}
.limitedGroupCover .ui.dropdownGroup >.menu::-webkit-scrollbar {
    width: 6px;
}
 
.limitedGroupCover .ui.dropdownGroup >.menu::-webkit-scrollbar-track {
    background:#fff;
    border-radius: 10px;
}
 
.limitedGroupCover .ui.dropdownGroup >.menu::-webkit-scrollbar-thumb {
    background:#cfcfcf;
    border-radius: 10px;
}

.ui.header.headingTitle{
    width:100%;
    float:left;
    display:flex;
    align-items:center;
    padding:10px 10px 10px 0;
    margin:0;
    font:600 15px 'Rubik',sans-serif;
}
.ui.header.headingTitle .ui.button{
    margin-left:auto;
}

.ui.input.toggleSearchBar{
    margin-left:auto;
    overflow:hidden;
    position:relative;
}
.ui.input.toggleSearchBar > img{
    position:absolute;
    top:8px;
    right:8px;
    z-index:0;
}
.ui.input.toggleSearchBar>input{
    width:30px;
    height:30px;
    margin:0 0 0 auto;
    padding:0 0 0 10px!important;
    background:none!important;
    border:solid 1px #D3D2DE;
    border-radius:3px;
    font:400 13px 'Rubik',sans-serif;
    z-index:1;
    transition:all .2s ease;
}
.ui.input.toggleSearchBar>input::placeholder{
    opacity:0;
    transition:all .2s ease;
}
.ui.input.toggleSearchBar>input:focus{
    width:168px;
    padding:0 40px 0 10px;
}
.ui.input.toggleSearchBar.open>input {
    width:168px;
}
.ui.input.toggleSearchBar>input:focus::placeholder{
    opacity:1;
}

.alignerScroll{
    width:100%; float:left;
    padding:0 10px 0 0;
}
.alignerHeight{
    min-height:195px;
}

.editUserItem{
    width:100%; float:left;
    min-height:60px;
    border-radius:2px;
    border:solid 1px #D3D2DE;
    margin:0 0 5px;
    padding:5px 15px;

    display:flex;
    align-items:center;
}

.editUserItem .ui.image{
    min-width:32px;
    width:32px;
    height:32px;
    border-radius:2px;
    overflow:hidden;
    margin:0 0 0 10px;
}
.editUserItem .ui.image img{
    max-width:100%;
    max-height:100%;
}

.editUserItem > .ui.button.buttonIcon{
    font-size:13px;
}


/*** teams ***/

.teamsTable .tableBoxRow .tableBoxColumn:nth-child(2){
    flex-direction:row;
    justify-content:flex-start;
    align-items:center;
}
.teamsTable .tableBoxColumn span.ui.image{
    min-width:30px;
    width:30px;
    height:30px;
    margin:0 5px 0 0;
    border-radius:30px;
    border:solid 1px #D3D2DE;
    overflow:hidden;
}
.teamsTable .tableBoxColumn span.ui.image img{
    max-width:100%;
    float:left;
}

.teamsTable .narrow:last-child{
    flex-direction:row;
    align-items:center;
}
.teamsTable .narrow:last-child form{
    margin:3px 0 0 5px;
}

/*** roles ***/

.tabRoleHead .textGroup{
    max-width:350px;
}
.tabRoleHead .textGroup > p{
    font-size:15px;
    width:auto;
}
.tabRoleHead .textGroup .ui.dropdownGroup > .text{
    color:#797B7D!important;
    max-width: 250px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.roleTable .tableBoxColumn{
    max-width:200px;
}
.roleTable .tableBoxColumn:first-child{
    flex:3;
    min-width:300px;
    max-width:100%;
    font-size:13px;
    justify-content:flex-start;
}
.roleTable .tableBoxColumn:last-child{
    max-width:100px;
}
.roleTable .textGroup{
    margin:0 0 10px;
}
.roleTable .textGroup:last-child{
    margin:0;
}

.roleTable .tableBoxColumn:first-child p{
    max-width:370px;
    font-size:14px;
}

.roleTable .tableBoxRow:nth-child(4) .tableBoxColumn:first-child p{
    max-width:460px;
}
.roleTable .tableBoxRow:last-child .tableBoxColumn:first-child p{
    max-width:100%;
}
/*** Modal ***/

.ui.modal.editUserModal{
    max-width:820px;
    padding-left:0;
    padding-right:0;
}
.ui.modal.editUserModal .aligner .header{
    margin:0;
}
.ui.modal.editUserModal > .content{
    width:100%; 
    float:left;
    padding:0 28px 0 38px;
    display:flex;
    border-bottom:solid 1px #D3D2DE;
    margin-bottom: 15px;
}
.ui.modal.editUserModal > .actions{
    padding:25px 38px 0;
    position:relative;
}
.ui.modal.editUserModal > .actions:before,
.ui.modal.editUserModal > .actions:after{
    content:'';
    width:38px;
    height:2px;
    background:#fff;
    position:absolute;
    left:0;
    top:-1px;
}
.ui.modal.editUserModal > .actions:after{
    left:auto;
    right:0;
}
.editUserModalSidebar{
    max-width:243px;
}
.editUserModalSidebar .alignerScroll{
    padding-right:16px;
    padding-top:20px;
}
.editUserModalSidebar .ui.inputForm{
    margin:0;
}
.editUserModalSidebar .ui.inputForm>input::placeholder{
    color:#7f7f7f!important;
}

.editUserActionsGroup{
    width:100%; float:left;
}
.editUserActionsGroup .ui.button.basic{
    width:100%;
    float:left;
    margin:0 0 10px;
    padding:0 12px;
    text-align:center;
}

.editUserModalContentBody{
    flex:1;
    border-left:solid 1px #D3D2DE;
}

.editUserModalContent{
    width:100%; float:left;
    padding:0 0 10px 22px;
    border-bottom:solid 1px #D3D2DE;
    position:relative;
}
.editUserModalContent:after{
    content:'';
    width:10px;
    height:2px;
    background:#fff;
    position:absolute;
    right:0;
    bottom:-2px;
}
.editUserModalContent:last-child{
    border-bottom:none;
    margin-top:4px;
}
.editUserModalContent:last-child:after{
    display:none;
}
.editUserModalContentBody .editUserModalContent:first-child .ui.header.headingTitle{
    padding:15px 0;
}

.editUserModalContent > div:last-child > div:nth-last-child(2){
    display:none;
}

.editUserContent{
    flex:1;
    padding:0 0 0 10px;
    font:400 15px 'Rubik',sans-serif;
}
.editUserContent p{
    margin:0;
}
.editUserContent span{
    font-size:13px;
    color:#667581;
}

.editUserModalSidebar .textGroup > b{
    color:#000;
}


/*** new ***/

.bulkDeleteHeading{
    width: 100%; float: left;
    text-align: center;
    margin:0 0 15px;
    font:500 18px 'Rubik',sans-serif;
    color:#e62600;
}

.ui.buttonIcon.bulkDelete{
    color:#F15944!important;
    margin:0 0 0 auto!important;
    float:right!important;
}
.ui.buttonIcon.bulkDelete img{
    width:20px;
}
.userTab .defaultWrap.flexible .ui.orange.button.invite-user-button{
    margin-left:20px;
}

.limitScrollHeight{
    width: 100%;
    float: left;
    max-height:40vh;
    overflow-y:auto;
}

.bulkDeleteModalItem{
    width: 100%; float: left;
    padding:10px 0;
    border-bottom:solid 1px #D3D2DE;
    display: flex;
    align-items: center;
    font:400 14px 'Rubik',sans-serif;
}

.bulkDeleteItemContent{
    min-width:150px;
}
.bulkDeleteItemContent small{
    font-size:10px;
}
.bulkDeleteItemContent strong{
    font-weight:500;
}
.bulkDeleteModalItem .ui.buttonIcon.crossInvite{
    margin:0 10px 0 auto!important;
}

.ui.checkbox.deleteCheckbox{
    float:left;
    margin:18px 0 0 15px;
}
.userTable .tableBoxRow:first-child .ui.checkbox.deleteCheckbox{
    margin:0 10px 0 0;
}
.userTable .tableBoxColumn:last-child{
    flex-direction: row;
    align-items: center;
}

.ui.buttonIcon.deleteButton{
    min-width:0;
    padding:5px 0 5px 5px!important;
    margin:-3px 0 0 0!important;
}
.tableBoxColumnLastCol form{
    height:14px;
}
.userTable .tableBoxRow:nth-child(2) .tableBoxColumn:first-child,
.userTableFirstCol{
    min-width:309px;
}

.ui.button.statusButtonTrigger{
    font:400 15px 'Rubik',sans-serif;
    display:inline-flex;
    align-items:center;
    color:#667581!important;
    padding:0;
    background:none;
    border:none;
    border-radius:0;
}
.ui.button.statusButtonTrigger img:last-child{
    margin:0 0 0 10px;
}

.ui.statusProcessingPopup{
    max-width:420px!important;
    width:420px!important;
	border:none;
    border-radius:3px;
    box-shadow:0 0 12px rgba(0,0,0,0.2);
}
.ui.statusProcessingPopup .ui.header{
    width: 100%; float:left;
    margin:0 0 10px;
    font:500 18px 'Rubik',sans-serif;
}
.processingStatus{
    width:100%; float:left;
    margin:15px 0 0;
    display:flex;
    align-items:flex-start;
    justify-content:space-between;
}
.processingStatus p{
    flex:1;
    width:20%;
    font:400 13px 'Rubik',sans-serif;
    color:#99A9C4;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:flex-start;
    position:relative;
}
.processingStatus p:after{
    content:'';
    width:56%;
    height:1px;
    border-bottom:dashed 1px #D3D2DE;
    position:absolute;
    top:9px;
    right:72%;
}
.processingStatus p:first-child:after{
    display:none;
}
.processingStatus .isCompleted:after,
.processingStatus .isProcessing:after{
    border-bottom:solid 1px #727AFF;
}
.processingStatus p span{
    width:21px;
    height:21px;
    border-radius:22px;
    border:solid 1px #D3D2DE;
    margin:0 0 6px;
    position:relative;
}
.isCompleted span{
    background:#727AFF;
    border:none!important;
}
.isCompleted span:after{
    content:'';
    background:url(./icon-check.svg) no-repeat center;
    background-size:9px auto;
    width:100%;
    height:100%;
    position:absolute;
    top:0;
    left:0;
    filter:saturate(0) brightness(100);
}

.processingStatus .ui.small.loader{
    margin:0 0 3px;
}
.processingStatus .ui.small.loader:after{
    width: 23px;
    height: 23px;
    border-color: #fff transparent transparent;
    border-width: 5px;
    margin: -1px 0px 0 -13px
}
.processingStatus .ui.loader:before{
    width:21px;
    height:21px;
    border:2px solid #727AFF;
}
.isProcessing{
    color:#727aff!important;
}
.isProcessing > span{
    display:none;
}
.isProcessing:last-child .ui.loader{
    display:none;
}

.isProcessing:last-child{
    color:#29AD73!important;
}
.isProcessing:last-child > span{
    display:block;
    background:#29AD73;
    border:none;
}
.isProcessing:last-child span:after{
    content:'';
    background:url(./icon-check.svg) no-repeat center;
    background-size:9px auto;
    width:100%;
    height:100%;
    position:absolute;
    top:0;
    left:0;
    filter:saturate(0) brightness(100);
}