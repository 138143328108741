.settingsTabGrid,
.settingsTabGrid > .ui.text.menu,
.settingsTabGrid > .ui.segment{
    height:calc(100vh - 62px);
}

.settingsTabGrid{
    width:100%; float:left;
    display:flex;
    align-items:flex-start;
}

.settingsTabGrid > .ui.text.menu{
    width:264px;
    margin:0;
    padding:0 20px;
    flex-direction:column;
    border-top:solid 30px #F4F7FC;
}

.settingsTabGrid > .ui.text.menu .item{
    width:100%; float:left;
    padding:24px 0;
    margin:0 0 11px;
    font:400 13px 'Rubik',sans-serif;
    color:#000;
    position:relative;
}
.settingsTabGrid > .ui.text.menu .item:after{
    content:'';
    width:100%;
    height:11px;
    background:#F4F7FC;
    position:absolute;
    bottom:-11px;
    pointer-events:none;
}
.settingsTabGrid > .ui.text.menu .active.item{
    color:#727AFF;
}

.settingsTabGrid > .ui.segment{
    padding:0;
    margin:0;
    border:none!important;
    border-radius:0!important;
    border-left:solid 1px #C0CBDA!important;
}

.settingsScrollLayout > div:nth-last-child(2){
    display:none;
}

.settingsLayout{
    width:100%; float:left;
    height:calc(100vh - 62px);
    padding:30px 35px;
}
.settingsLayout .tableBox{
    min-height:calc(100vh - 343px);
}
.settingsLayout .tableBox.roleTable{
    min-height:calc(100vh - 329px);
}

.settingsLayout h6.ui.header{
    font-size:16px!important;
}

.settingsWrapper{
    width:100%;
    max-width:790px;
    float:left;
}

.developersWrapper{
    max-width:1010px;
}

.sectionSettings{
    width:100%; float:left;
    padding:0 0 0 40px;
    position:relative;
}
.sectionSettings > img{
    position:absolute;
    top:0;
    left:0;
}

.ui.button.clickHereButton,
.ui.button.clickHereButton:focus{
    padding:0!important;
    border-radius:0;
    background:none!important;
    font:400 14px 'Rubik',sans-serif;
    color:#737BFF!important;
    margin:5px 0 15px;
}
.ui.button.clickHereButton:hover{
    text-decoration:underline;
}

.feedbackArea{
    width:100%; float:left;
    height:100px;
    background:#F2F1F5;
    padding:10px;
    resize:none;
    border:none;
    border-radius:0;
}
.feedbackArea:focus{
    box-shadow:inset 0 0 15px 0 rgba(0,0,0,0.1);
}

div.ui.dropdownGroup.flagGroup{
    height:25px;
    margin:0 0 10px;
    /* background:url(/img/icon-angle-down.svg) no-repeat right; */
    background:url(./icon-angle-down.svg) no-repeat right;
}
div.ui.dropdownGroup.flagGroup > img{
    float:left;
    margin:0px 5px 0 0;
}
div.ui.dropdownGroup.flagGroup > .text{
    margin-top:2px;
}

/*** modals ***/

.closeAccountType{
    width:100%; float:left;
    margin:0 0 20px;
    position:relative;
}
.closeAccountType:last-child{
    margin:0;
}
.closeAccountType p{
    padding-left:24px;
    font:400 13px/18px 'Rubik',sans-serif;
    color:#000;
}
.closeAccountType .ui.checkboxGroup{
    margin:0 0 10px;
}
.warningPara,
.deleteDataItem{
    width:100%; float:left;
    font:500 14px 'Rubik',sans-serif;
}
.deleteDataItem{
    color:#667581;
}
.warningPara{
    color:#E73434;
    margin:0 0 20px;
}
.deleteDataItem{
    display:flex;
    align-items:flex-start;
    font-weight:400;
    margin:0 0 15px;
}
.deleteDataItem .ui.checkbox{
    width:auto;
    margin:1.5px 15px 0 0;
}
.deleteDataItem strong{
    display:block;
    font-weight:500;
}

.onHoldText{
    position:absolute;
    right:0;
    top:0;
    font:400 12px 'Rubik',sans-serif;
    color:#000;
}
.closeAccountType.disabled .ui.checkbox,
.closeAccountType.disabled p{
    opacity:.5;
    pointer-events:none;
}

.ui.modal.modalLayout.actionMargin  > .actions{
    padding-top:25px;
}

/*** tabs ***/

.tabLayout{
    float:left;
    width:100%;
    padding:30px 35px;
}

.boxTabs{
    width:100%; float:left;
}

.boxTabs .ui.secondary.menu{
    width:100%; float:left;
    border-bottom:solid 1px #D3D2DE;
    min-height:0;
    margin:0;
}

.boxTabs .ui.secondary.menu .item{
    font:400 15px/16px 'Rubik',sans-serif;
    color:#000;
    padding:8px 0;
    margin:0 30px -1px 0;
    border-radius:0!important;
    background:none!important;
}

.boxTabs .ui.secondary.menu .active.item{
    box-shadow:inset 0 -3px 0 0 #727AFF!important;
}

.boxTabs > .ui.segment{
    width:100%; float:left;
    margin:0!important;
    padding:0!important;
    border:none!important;
    background:none;
}

/*** Table ***/


.tableBox{
    width:100%; float:left;
    margin:15px 0 20px;
    padding:0;
}

.tableBoxRow{
    min-width:100%;
    float:left;
    display: flex;
    padding:0;
    border-radius:4px;
    margin:0 0 5px;
    background:#fff;
}

.tableBoxRow:first-child{
    background:none;
}
.tableBoxRow:first-child .tableBoxColumn{
    padding:10px 10px 10px 15px;
    min-height:0;
}
.tableBoxRow:first-child .tableBoxColumn p{
    font:400 12px 'Rubik',sans-serif;
    color:#667581;
    margin:0;
}

.tableBoxColumn{
    flex:1;
    min-width:200px;
    max-width:400px;
    min-height:40px;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:flex-start;
    padding:15px 10px 15px 15px;
    position:relative;
    
    font:400 15px 'Rubik', sans-serif;
    color:#000;
}
.tableBoxColumn > p{
    margin:0;
}
.tableBoxColumn > p small{
    display:block;
    font-size:13px;
    color:#667581;
}
.tableBoxColumn > b{
    font:600 15px 'Rubik',sans-serif;
    margin:0 0 8px;
}

.tableBoxColumn.narrow{
    min-width:60px;
    max-width:100px;
}

.loadingColumn{
    width:100%; float:left;
    padding:40px 0;
    display:flex;
    align-items:center;
    justify-content:center;
    text-align:center;
    font:500 15px 'Rubik',sans-serif;
}

.emptyTableList{
    padding:12px;
    display:flex;
    align-items:center;
}
.emptyTableList p{
    flex:1;
    font:400 13px 'Rubik',sans-serif;
    color:#99A9C4;
    padding-left:20px;
}
.emptyTableList p strong{
    display:block;
    font-weight:500;
    font-size:15px;
    color:#727AFF;
    margin:0 0 5px;
}

/*** roles ***/

.tabRoleModal .defaultWrap.flexible{
    justify-content:space-between;
}
.tabRoleModal .defaultWrap.flexible .textGroup{
    max-width:225px;
}
.roleActionButton{
    margin:10px 0 15px!important;
}
.roleActionButton .ui.buttonIcon{
}
.roleActionButton .ui.buttonIcon.red{
    color:#F15944!important;
}
.tabRoleModal .ui.checkbox.secondary{
    margin:10px 0 30px;
}
.tableRoleModal .limitedGroupCover{
    margin-top:20px;
}

/**/

.field-error{
    width:100%; float:left;
    min-height:36px;
    padding:5px 0 5px 30px;
    margin:0 0 10px;
    border:solid 1px #e62600;
    border-left-width:8px;
    background:url(./icon-error.svg) no-repeat 8px center #fcf4f2;
    background-size:14px 14px;
    border-radius:2px;
    font:400 14px/18px 'Rubik',sans-serif!important;
    color:#a31b00;
    display:flex;
    align-items:center;
}

.passwordField .textGroupContent > .field-error{
    margin-top:5px!important;
}

.gapBottom{
    margin:0 0 20px;
}

/*** developers new ***/

.ui.modal.modalLayout.alignCenterModal{
    top:50%;
    transform:translateY(-50%);
}

.tableBoxRestrictedAccess{
    min-height:0!important;
}

.tableBoxRestrictedAccess .tableBoxRow:last-child{
    margin:0 0 1px;
}

.tableBoxRestrictedAccess .alignerTable .tableBoxRow:first-child{
    border: solid 1px #D3D2DE;
    border-radius: 3px;
}
.tableBoxRestrictedAccess .alignerTable .tableBoxRow:first-child .tableBoxColumn{
    min-height: 40px;
    font: 400 15px 'Rubik', sans-serif!important;
    color: #000!important;
}
.tableBoxRestrictedAccess .alignerTable .tableBoxRow:first-child .tableBoxColumn p{
    font: 400 15px 'Rubik', sans-serif!important;
    color: #000!important;
}

.tableBoxRestrictedAccess .tableBoxRow:not(:first-child){
    border:solid 1px #D3D2DE;
    border-radius:3px;
}
.tableBoxRestrictedAccess .tableBoxColumn{
    width:22%;
    min-width:22%;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    padding:5px 10px!important;
}
.tableBoxRestrictedAccess .tableBoxColumn:first-child{flex:1;width:35%; min-width:35%;}
.tableBoxRestrictedAccess .tableBoxColumn:last-child{width:12%;min-width:12%;max-width:14%;padding:5px 5px 5px 0!important;}
.ui.header.headingRestrictedAccess{
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    margin:0!important;
}
.emptytablebox{
    width:100%; float:left;
    background:#F4F7FC;
    border-radius:3px;
    padding:10px;
    font:400 14px 'Rubik',sans-serif;
    margin:0 0 15px;
}
.appRowRestricedAccess .videoWrap{
    margin-top:65px;
}
.ui.buttonIcon.copyKeyAccess{margin-left:5px!important;}
.tableboxActions{
    width:auto;
    display: inline-flex;
    margin-left: auto;
}
.tableboxActions .ui.button.buttonIcon{margin:0 0 0 6px;}
.alignerTable{
    width:100%; float:left;
    padding:0 2px 0 0;
}

.modalRow{
    width:100%; float:left;
    display: flex;
    justify-content: space-between;
}
.modalRow .textGroup{
    width:48%;
}
.modalRow .ui.dropdownGroup > .icon{
    margin:8px 5px 0 0;
    font-size:12px;
}

.tgRestrictionModal{width:auto!important; min-width:48%; max-width:100%;}
.tgRestrictionModal .ui.dropdownGroup{width:auto;}
.tgRestrictionModal .ui.dropdownGroup > .icon{margin:8px 5px 0 15px;}

.apiKeybox{
    width:100%; float:left;
    background:#F3F6FB;
    border:dotted 2px #D3D2DE;
    border-radius:4px;
    font:400 13px 'Rubik',sans-serif;
    color:#000;
    position: relative;
    padding:5px 25px 5px 5px;
}
.apiKeybox span{
    float:left;
    max-width:100%;
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
}
.apiKeybox .ui.button.buttonIcon{
    position: absolute;
    right:2px;
    top: 5px;
    z-index:1;
}

.accessRow{
    width:100%; float:left;
    display: flex;
    justify-content: space-between;
    align-items:flex-end;
}
.accessColumn{
    flex:1;
    max-width:48%;
}
.ac1{max-width:55%;}

.accessGroup{
    width:100%; float:left;
    margin:0 0 10px;
    display: flex;
    align-items: center;
}
.accessGroup:last-child{margin:0;}
.accessGroup .ui.buttonIcon{margin:0 0 0 10px!important;}

.ui.input.inputField{
    width:100%; float:left;
    height:22px;
    color:#333;
}
.ui.input.inputField>input{
    border:none;
    border-radius:0;
    border-bottom:solid 1px #D3D2DE;
    font:400 14px 'Rubik',sans-serif;
    padding:4px 0;
}

.ui.modal.mapsWebApiModal .actions{
    padding:15px 0 0;   
}

.accessTabBox{
    width:100%; float:left;
    min-height:290px;
}
.accessTabBox .ui.secondary.pointing.menu{
    margin:0;
    padding:0!important;
    border-bottom:solid 1px #D3D2DE;
}
.accessTabBox .ui.secondary.pointing.menu .item{
    font:400 15px 'Rubik',sans-serif;
    color:#000;
    padding:5px 0;
    margin:0 30px -1px 0;
}
.accessTabBox .ui.secondary.pointing.menu .active.item{
    font-weight:400;
    border-bottom:solid 2px #737BFF!important;
}

.accessTabBox .ui.segment{
    padding:20px 0 0;
    margin:0!important;
    border-radius:0;
    border:none;
    margin:0;
    box-shadow:none;
}

.alignerAccessTab{width:100%; float:left; padding:0 10px 10px 0;}
.alignerAccessTabScroll{width:100%; float:left; padding:0 10px 10px 0; max-height:230px; overflow-y:auto;}

.alignerAccessTabScroll::-webkit-scrollbar {
    width: 6px;
}
 
.alignerAccessTabScroll::-webkit-scrollbar-track {
    background:#fff;
    border-radius: 10px;
}
 
.alignerAccessTabScroll::-webkit-scrollbar-thumb {
    background:#cfcfcf;
    border-radius: 10px;
}

.accessRowDate .accessColumn{
    max-width:81%;
}
.accessDateGroup{
    width:100%; float:left;
    display: flex;
    align-items: center;
    margin:0 0 15px;
}
.accessDateGroup:last-child{margin:0;}
.accessDateGroup .textGroup{
    width:48%;
    margin:0;
}
.accessDateGroup .textGroup > p{width:auto; flex:1; color:#667581; font:400 14px 'Rubik',sans-serif;}
.accessDateGroup .inputField{
    width:100px;
}
.accessDateGroup .textGroup .field,
.accessDateGroup .textGroup .ui.input.inputField{
    width:90px;
    display: inline-flex;
    align-items: center;
    position: relative;
}
.accessDateGroup .textGroup .ui.input.inputField>input{font-size:12px;padding-left:0!important;}
.accessDateGroup .field .ui.input{
    width:100%;
}

.accessDateGroup .textGroup .ui.icon.input>i.icon{
    position: absolute;
    left:auto;
    right: 0;
    width:12px;
}


.accessDateGroup .field .ui.input > input{
    padding:3px 16px 3px 0!important;
    border-radius:0;
    border:none;
    border-bottom:solid 1px #D3D2DE;
    font:400 12px 'Rubik',sans-serif;
}
.accessDateGroup .field .ui.icon.input>i.icon{
    width:13px;
    opacity:.4!important;
}
.accessDateGroup > span{
    flex:1;
    font:400 15px 'Rubik',sans-serif;
    text-align: center;
    padding:0 5px;
}

.accessDateGroup > .ui.buttonIcon{margin:0 0 0 10px!important;}

.ui.popup.restrictionColumnPopup{
    white-space:nowrap;
}










