.dashboardSection .container{
    width:100%;
    max-width:1108px;
    margin:0 auto;
    padding:0 15px;
}

.dashboardBanner{
    width:100%; float:left;
    margin:0 0 0;
    padding:0 20px;
    /* background:url(/img/hub/banner-bg.png) no-repeat center; */
    background:url(./banner-bg.png) no-repeat center;
    background-size:auto 100%;
    height:211px;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    color:#fff;
    position:relative;
}

.dashboardBanner h1.ui.header{
    font:500 36px 'Rubik',sans-serif;
    color:#fff;
    margin-bottom:19px;
}

.dashboardBanner span{
    font:400 22px 'Rubik',sans-serif;
    color:rgba(255,255,255,0.8);
    text-align:center;
}

@media (max-width:480px){
    .mapstedProductColumn{
        min-width:180px!important;
        width:100%!important;
        margin-right:0!important;
    }
}