.ui.dimmer {
    background: rgba(0, 0, 0, 0.3) !important;
  }
  
  .ui.modal.modalLayout {
    width: 96%;
    max-width: 576px;
    border-radius: 10px;
    padding-bottom: 20px;
    border: solid 1px #d3d2de;
  }
  
  .ui.modal.large.modalLayout {
    max-width: 821px;
  }
  
  .ui.modalLayout .ui.closeBtn:before,
  .ui.modalLayout .ui.closeBtn:after {
    background: #000;
    -webkit-user-select: none;
    user-select: none;
    transition: opacity .1s ease,background-color .1s ease,color .1s ease,box-shadow .1s ease,background .1s ease;
    will-change: auto;
    -webkit-tap-highlight-color: transparent;
  }
  
  
  .ui.modal.modalLayout > .header,
  .ui.modal.modalLayout > .aligner .header {
    width: 100%;
    font: 500 18px "Rubik", sans-serif !important;
    background-color: transparent;
    padding: 10px;
  
  }
  .ui.modal.modalLayout > .aligner {
    padding: 0 ;
  }
  
  .ui.modal.modalLayout > .content {
    width: 100%;
    position: relative;
    color: #000 auto;
    float: left;
    /* background-color: transparent; */
  
    padding: .9rem 1.5rem; 
  
  }
  
  /* .ui.modal.modalLayout > .actions {
      background: none !important; 
      position: relative;
      float: right;
      width: 100%;
      margin: 0;
      border: none;
      margin-right: 1.5rem;
      margin-top: -3rem;
      padding: 4rem;
      border-top: none !important;
  } */
  .ui.modal.modalLayout > .modal-bottom.actions {
      background: none !important;
      position: relative;
      float: right;
      width: 100%;
      margin: 0;
      border: none;
      /* margin-right: 1.5rem; */
      /* margin-top: -3rem; */
      /* padding: 4rem; */
      /* border-top: none !important; */
  }
  .modal-bottom{
      padding: 1rem;
      margin-bottom: 2rem;
      width: auto;
      position: relative;
  }
  
   .ui.negative.button {
    margin: 0;
  }
  
  .ui.modal.successModal.modalLayout {
    max-width: 604px;
    border: none;
    text-align: center;
    padding-bottom: 38px;
  }
  .ui.modal.successModal.modalLayout .ui.closeBtn {
    opacity: 0.5;
  }
  .ui.modal.successModal.modalLayout .paraModal {
    font: 400 11px/16px "Rubik", sans-serif;
  }
  .successIcon {
    width: 100%;
    float: left;
    margin: 0 0 30px;
  }
  
  .ui.modal.successModal.modalLayout > .aligner {
    display: none;
  }
  
  /** regular **/
  
  .ui.modal.regularModal {
    border: none;
    max-width: 790px;
  }
  .ui.modal.regularModal > .header {
    font: 600 24px "Rubik", sans-serif !important;
    text-align: center;
  
  }
  
  
  
  
  /* ModalLayout 2 CSS */
  
  .dropdown-option{
    width: 100%;
  }
  
  
  
  .ui.modal>.actions {
    margin-right: 20px;
    background-color: white !important;
  }
  
  
  .modal2-label{
    
    font: 500 15px "Rubik", sans-serif;
    margin-bottom: 8px;
  
  }
  .modal2-label-text{
    font: 400 15px/16px "Rubik", sans-serif;
    font-weight: normal;
    line-height: 20px;
  }
  
  .modal2-main-div{
    display: grid;
    flex-direction: row;
    row-gap: 20px;
  }
  .copybutton-parent .webCopyButton > img{
    height: 20px;
    width: 20px;
    margin-top: 5px;
  }
  .modal2-actions{
    margin-top: 25px !important;
  }
  .checkbox-container {
    display: flex;
    align-items: center;
  }
  
  .checkbox-input {
    margin-right: 5px;
    width: 15px;
    height: 15px;
    accent-color: limegreen !important;
  }
  
  .checkbox-svg {
    width: 20px;
    height: 20px;
    fill: green;
    display: none;
  }
  
  .apiKeybox {
    padding: 12px;
    display: flex;
    align-items: center;
    border-radius: 10px;
    border: dashed 2px lightgray;
  }