.inputFormGroup, .inputFormGroup > b, .inputFormGroup > span, .inputFormGroup > .ui.input,
.textGroup, .textGroup > b, .textGroup > p{
    width:100%;
    float:left;
    font-family:'Rubik', sans-serif !important;
}

.inputFormGroup, .textGroup{
    margin-bottom:30px;
}
    .inputFormGroup > b,
    .textGroup > b{
        font-size:14px;
        font-weight:500;
        color:#000;
        margin-bottom:9px!important;
    }

    .inputFormGroup > span,
    .textGroup > span{
        font-size:13px;
        color:#667581;
        margin:8px 0 0;
    }

.inputFormGroup > b i.icon,
.textGroup > b i.icon{
    font-size:15px;
    color:#727aff!important;
    margin:-2px 0 0 5px;
    position:relative;
    cursor:pointer;
}

.inputFormGroup > .ui.input > input{
    border-radius:0;
    border:none;
    background:#fff;
    color:#000;
    font:400 15px 'Rubik',sans-serif;
    border-bottom:solid 1px #d9d8e2;
    padding:0;
    height:20px;
}
.inputFormGroup > .ui.input > input::placeholder{
    color:#7f7f7f;
}

.inputFormGroup > .ui.input.error{
    margin: 0px;
}
.inputFormGroup > .ui.input.error > input::placeholder{
    color:#7f7f7f;
}
.inputFormGroup > .ui.input.error > input{
    border-bottom: 1px solid red;
    background-color: inherit;
}

.inputFormGroup > .ui.input > input:focus,
.inputFormGroup >.ui.search.dropdown.active{
    border-color:#5694fb!important;
}
.inputFormGroup > .ui.input.error > input:focus{
    border-color:red!important;
}

.inputFormGroup > .ui.dropdown,
.ui.dropdown.dropdownModal{
    width:100%;
    float:left;
    border-radius:0;
    border:none;
    background:#fff;
    color:#000;
    border-bottom:solid 1px #d9d8e2;
    padding:0;
    height:20px;
}

.inputFormGroup > .ui.dropdown i.icon,
.ui.dropdown.dropdownModal i.icon{
    float:right;
    width:auto;
}

.inputFormGroup > .ui.search.dropdown>i.icon{
    position:absolute;
    right:0;
    width:11px;
    height:20px;
    background:url("./caret.svg") no-repeat center;

}

.inputFormGroup > .ui.search.dropdown>i.icon.angle.down:before,
.ui.search.dropdown.dropdownModal>i.icon.angle.down:before{
    display:none;
}

.intl-tel-input.inputFormGroup{
    margin-bottom:0;
}

.inputFormGroup > .ui.dropdown .menu,
.inputFormGroup > .ui.search.dropdown .menu,
.ui.dropdown.dropdownModal .menu{
    width:100%;
    margin-top:1px;
    border-radius:2px;
    border:solid 1px #d3d2de;
    box-shadow:0 5px 20px rgba(0,0,0,0.3);
    overflow-y:auto;
    overflow-x:hidden;
}

.ui.dropdown.dropdownModal .menu{
    max-height:120px;
    overflow-x:hidden;
    overflow-y:auto;
}

.inputFormGroup > .ui.dropdown .menu .item,
.ui.dropdown.dropdownModal .menu .item{
    font:400 14px 'Rubik',sans-serif!important;
    color:#000;
    padding:9px 15px;
}

.inputFormGroup > .ui.dropdown .menu .selected.item{
    background:#e5f1fd;
}

.ui.dropdown.dropdownIcon{
    display:flex;

}
.ui.dropdown.dropdownIcon > span{
    order:1;
    padding:0 0;
    border-right:solid 1px #ccc;
    cursor:pointer;
    z-index:10;
}
.ui.dropdown.dropdownIcon > span:after{
    content:'';
    float:right;
    width:28px;
    height:100%;
    background:url("./caret.svg") no-repeat center;
}
.ui.search.dropdown.dropdownIcon>.text{
    order:2;
    padding-left:13px;
}
.ui.search.dropdown.dropdownIcon > input{
    padding-left:65px!important;
}

/*** textGroup ***/

.textGroup p{
    font-size:15px;
    line-height:24px;
    color:#000;
}
