.phoneBox{
    width:100%; float:left;
}

.phoneBox input{
    width:100%; float:left;
    background:#fff;
    color:#000;
    border-radius:0;
    border:none;
    border-bottom:solid 1px #d9d8e2;
    height:21px;
    cursor:text;
}

.phoneBox input::placeholder{
    color:#7f7f7f;
}

.phoneBox{
    width:100%; float:left;
    font:400 15px 'Rubik',sans-serif;
    color:#000;
    display:flex;
    align-items:center;
}

.phoneBox .selected-flag{
    width:auto!important;
    background:url(./icon-chevron-down.svg) no-repeat 30px center #fff!important;
    padding:0;
    outline:0;
    display:flex!important;
    align-items:center;
    justify-content:space-between;
}
.phoneBox.intl-tel-input .flag-container .arrow,
.phoneBox.intl-tel-input .flag-container .arrow::after{
    display:none!important;
}

.phoneBox.intl-tel-input.allow-dropdown .flag-container{
    width:auto;
    padding:0;
    position:relative;
    margin:0;
    display:flex;
    align-items:center;
}
.phoneBox.intl-tel-input.allow-dropdown.separate-dial-code .selected-dial-code{
    position:relative!important;
    right: auto;
    padding: 0;
    border-left:solid 1px #d9d8e2;
    color:#A3ACB3;
    padding:0 0 0 7px;
    margin:0 7px 0 30px;
}
.phoneBox .ui.dropdown{
    padding:0!important;
}
.phoneBox.intl-tel-input.allow-dropdown .flag-container > .iti-flag{
    position:absolute;
    left:0;
}

/***new***/

.phoneBoxNew{
    width:100%; float:left;
    max-width:350px;
    border:none!important;
}
.phoneBoxNew .ui.selection.dropdown{
    border-radius: 0;
    padding: 0!important;
    height:25px!important;
    min-height:15px;
    display: flex;
    align-items: center;
    min-width: 86px;
    width: auto;
    font:400 14px 'Rubik',sans-serif;
    color:#A3ACB3;
    border:none!important;
    border-bottom:solid 1px #d9d8e2!important;
}
.phoneBoxNew .ui.selection.dropdown input{
    padding:0!important;
    height:25px;
}
.phoneBoxNew .ui.selection.dropdown>.dropdown.icon{
    position: absolute;
    right: 15px;
    width: 10px;
    height: 10px;
    top: 4px;
}
.phoneBoxNew .ui.search.dropdown .menu{
    width:160px;
}
.phoneBoxNew.ui.input>input{
    flex:1;
    height:25px;
    padding:0 0 0 10px;
    border-radius:0;
    border:none!important;
    border-bottom:solid 1px #d9d8e2!important;
    border-left:solid 1px #d9d8e2!important;
    font:400 14px 'Rubik',sans-serif;
}

.phoneBox input{
    width:100%; float:left;
    background:#fff;
    color:#000;
    border-radius:0;
    height:21px;
    cursor:text;
}

.phoneBox input::placeholder{
    color:#7f7f7f;
}

.phoneBox{
    width:100%; float:left;
    font:400 15px 'Rubik',sans-serif;
    color:#000;
    display:flex;
    align-items:center;
}

.phoneBox .selected-flag{
    width:auto!important;
    background:url(./icon-chevron-down.svg) no-repeat 30px center #fff!important;
    padding:0;
    outline:0;
    display:flex!important;
    align-items:center;
    justify-content:space-between;
}
.phoneBox.intl-tel-input .flag-container .arrow,
.phoneBox.intl-tel-input .flag-container .arrow::after{
    display:none!important;
}

.phoneBox.intl-tel-input.allow-dropdown .flag-container{
    width:auto;
    padding:0;
    position:relative;
    margin:0;
    display:flex;
    align-items:center;
}
.phoneBox.intl-tel-input.allow-dropdown.separate-dial-code .selected-dial-code{
    position:relative!important;
    right: auto;
    padding: 0;
    border-left:solid 1px #d9d8e2;
    color:#A3ACB3;
    padding:0 0 0 7px;
    margin:0 7px 0 30px;
}
.phoneBox .ui.dropdown{
    padding:0!important;
}
.phoneBox.intl-tel-input.allow-dropdown .flag-container > .iti-flag{
    position:absolute;
    left:0;
}