.flexiblePreference{
    width:100%; float:left;
    display:flex;
    align-items:center;
}
.flexiblePreference .textGroup{
    width:auto;
    margin:0 35px 0 0;
}
.flexiblePreference .textGroup .ui.input{
    margin:0;
    width:195px;
}
.flexiblePreference .textGroup .textGroupContent{
    width:195px;
    position:relative;
}
.flexiblePreference .textGroup .textGroupContent .extraDetail{
    position:absolute;
    left:0;
    top:26px;
    max-width:180px;
}
.flexiblePreference .expanded{
    margin-bottom:40px;
}

.flexiblePreference div.ui.dropdownGroup.flagGroup{
    background:none;
    line-height:19px;
}
.flexiblePreference.fluid{
    display:flex;
    justify-content:space-between;
}
.flexiblePreference.fluid .tgFlexible > p{
    width:100px;
}
.flexiblePreference.fluid .textGroup{
    flex:1;
    margin:0;
    max-width:48%;
    align-items:flex-start;
}
.flexiblePreference.fluid .textGroup .textGroupContent{
    flex-direction:column;
}
.flexiblePreference.fluid .textGroup .textGroupContent .extraDetail{
    position:relative;
    top:auto;
}

.passwordField{
    margin-top:10px!important;
}

div.passwordField > p{
    font:500 15px 'Rubik',sans-serif;
    margin:0 0 5px;
}

.passwordField .textGroupContent{
    position:relative;
}

.passwordField .textGroupContent .ui.buttonIcon{
    position:absolute;
    right:0;
    top:0;
}
.passwordField .textGroupContent .ui.buttonIcon img{
    height:10px;
}
.passwordField .textGroupContent > span{
    width:100%; float:left;
    margin-top:5px;
    font:400 12px 'Rubik',sans-serif;
}
.passwordField .ui.input{
    width:100%;
    float:left;
    position:relative;
}
.passwordField .ui.input>input{
    padding:0 20px 5px 0;
    font:400 15px 'Rubik',sans-serif;
    border:none;
    border-bottom:solid 1px #D3D2DE;
    border-radius:0;
}
input::-ms-reveal,
input::-ms-clear {
  display: none;
}

.timeDateMargin h2.ui.header{
    margin:0 0 15px;
}