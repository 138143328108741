.mapstedProductRow{
    width:100%; float:left;
    margin:0 0 41px;
}

.productHeading{
    width:100%; float:left;
    margin:0 0 47px;
    border-bottom:solid 1px #eee;
}

.productHeading h2.ui.header{
    width:100%;
    max-width:100%;
    float:left;
    padding:0 0 7px;
    font:500 20px 'Rubik',sans-serif!important;
    color:#222;
    position:relative;
}

.productHeading h2.ui.header .sub.header{
    width:100%;
    font:400 15px 'Rubik',sans-serif;
    letter-spacing:-0.5px;
    margin-top:8px;
    color:#000!important;
}

.productHeading h2.ui.header:after{
    content:'';
    width:344px;
    max-width:100%;
    height:3px;
    background:#737bff;
    position:absolute;
    left:0;
    bottom:-1px;
}

.productHeading.purple h2.ui.header:after{
    background: #6c3db3;
}
.productHeading.cyan h2.ui.header:after{
    background:#47cac5;
}
.productHeading.orange h2.ui.header:after{
    background:#f15944;
}

.productHeading.yellow h2.ui.header:after{
    background:#fffeac;
}

.mapstedRowContent,
.mapstedProductColumn,
.ui.segment.mapstedProductBox{
    display:flex;
}

.mapstedRowContent{
    width:100%; float:left;
    flex-wrap:wrap;
}

.mapstedProductColumn{
    min-width:344px;
    width:344px;
    max-width:100%;
    float:left;
    margin:0 22px 25px 0;
    flex-direction:column;
}

.mapstedProductColumn:last-child{
    margin-right:0;
}

.mapstedProductColumn > .ui.header{
    font:500 15px 'Rubik',sans-serif;
    color:#222;
    margin-bottom:11px!important;
}

.ui.segment.mapstedProductBox{
    flex:1;
    border:solid 1px #dadde1;
    background:#fff;
    padding:0 0 13px;
    margin:0;
    border-radius:0;
    box-shadow:none;
    flex-direction:column;
    align-items:flex-start;
}

.ui.segment.mapstedProductBox .ui.image{
    width:100%;
    height:192px;
    background:#fafafa;
    display:flex;
    align-items:center;
    justify-content:center;
}
.ui.segment.mapstedProductBox .ui.image img{
    transform:scale(0.8);
}


.ui.segment.mapstedProductBox p{
    flex:1;
    padding:16px 20px 9px;
    margin:0;
    font:400 14px/20px 'Rubik',sans-serif;
    color:#000;
}

.ui.segment.mapstedProductBox .ui.buttonIcon{
    margin-left:20px;
    color:#737bff!important;
    font-size:13px;
    padding:0!important;
}

.labelStatus{
    width:auto;
    display:flex;
    align-items:center;
    font:400 13px 'Rubik',sans-serif;
    color:#00bf94;
    margin-left:20px;
}
.labelStatus img{
    margin:0 5px 0 0;
}

.ui.button.downloadIcon img{
    width:14px;
    height:13px;
}

.accessAligner{
    width:100%; float:left;
    padding:0 1px 0 0;
}

.accessAligner .paginationBar{
    flex-direction:column;
    align-items:center;
    justify-content:center;
}
.accessAligner .paginationBar .ui.pagination{
    margin:10px 0;
}
.accessAligner .paginationBar .displayText{
    position:relative;
}

.mapstedProductAccess .mapstedProductColumn > .ui.header span{
    display:block;
    font:400 13px 'Rubik',sans-serif;
    margin:8px 0 5px;
    border-top:solid 1px #D3D2DE;
    padding:5px 0 0;
    color:#667581;
}

.accountAccessList{
    width:100%; float:left;
    margin:10px 0 20px;
}
.accountListItem{
    width:100%; float:left;
    margin:0 0 5px;
    padding:0 10px 0 0;
    border:solid 1px #D3D2DE;
    border-radius:3px;
    background:#fff;
    display:flex;
    align-items:stretch;
    overflow:hidden;
}
.accountListItem:last-child{
    margin:0 0 3px;
}
.accountListItem .ui.image{
    min-width:60px;
    margin:0 15px 0 0;
    background:#F3F6FB;
    display:flex;
    align-items:center;
    justify-content:center;
}
.accountListItem .ui.image img{
    width:30px;
}
.accountListItem p{
    font:400 16px 'Rubik',sans-serif;
    padding:10px 0;
}
.accountListItem p span{
    display:block;
    font-size:13px;
    margin:5px 0 0;
}

.scrollAccess > div:nth-last-child(2){
    display:none;
}

.ui.accessInput{
    width:100%; float:left;
    position:relative;
    margin:0 0 10px;
}
.ui.accessInput>input{
    width:100%; border:none!important;
    height:30px;
    padding:0 20px 0 0!important;
    border-radius:0!important;
    border-bottom:solid 1px #D3D2DE!important;
    font:400 13px 'Rubik',sans-serif!important;
}
.ui.accessInput > img{
    position:absolute;
    top:10px;
    right:0;
}

.dashboardSection .container > .mapstedProductRow:nth-last-child(2),
.dashboardSection .container > .mapstedProductRow:last-child{
    width:100%;
    max-width:344px;
    margin:0 22px 50px 0!important;
}

.sectionAccess{
    width:100%; float:left;
    background:#F3F6FB;
    padding:80px 0 30px;
    margin:0 0 50px;
}
.sectionAccess .container {
    width: 100%;
    max-width: 1108px;
    margin: 0 auto;
    padding: 0 15px;
}

.mapstedProductAccess .productHeading{
    border:none;
    padding:0;
    margin:0 0 15px;
}
.mapstedProductAccess .productHeading h2.ui.header .sub.header{
    border-top:solid 1px #D3D2DE;
    padding:10px 0 0;
    color:#667581!important;
    font-size:13px;
}
.mapstedProductAccess .productHeading.purple h2.ui.header:after{
    display:none;
}
.mapstedProductAccess .mapstedProductColumn{
    background:#fff;
    border-radius:4px;
    padding:25px 25px;
}

.noneItem{
    width:100%; float:left;
    background:#F3F6FB;
    border-radius:3px;
    border:solid 1px #D3D2DE!important;
    font:400 13px 'Rubik',sans-serif;
    color:#667581;
    padding:10px;
    margin:0 0 2px;
}