*{
    outline:0;
}

body{
    padding-top:62px!important;
}

h2.ui.header{
    width:100%; float:left;
    font:600 18px 'Rubik',sans-serif!important;
    color:#000;
    margin:0 0 30px;
}

h6.ui.header{
    font:600 16px 'Rubik',sans-serif;
    color:#000;
    margin-top:0!important;
    margin:0 0 15px;
}

h5.ui.header{
    font:600 15px 'Rubik',sans-serif;
    color:#000;
    margin-top:0!important;
    margin:0 0 15px;
}

.paragraph{
    width:100%; float:left;
    font:400 15px/20px 'Rubik',sans-serif;
    color:#667581!important;
}
.paragraphSmall{
    font-size:13px;
}
.paragraph.dark{
    color:#000!important;
}
.paragraph a{
    color:#727AFF;
}
.paragraph a:hover{
    text-decoration:underline;
}

.ui.popup{
    border:solid 1px #D3D2DE;
    border-radius:2px;
    box-shadow:0 2px 8px rgba(0,0,0,0.1);
    font:400 13px/16px 'Rubik',sans-serif;
    color:#000;
}
.ui.popup a{
    color:#727AFF;
}

span.ui.infoButton{
    display:inline-block;
    cursor:pointer;
}

.ui.positive.button,
.ui.negative.button,
.ui.orange.button,
.ui.blue.button,
.ui.grey.button{
    width:auto;
    min-width:100px;
    min-height:36px;
    border-radius:4px;
    font:600 13px 'Rubik',sans-serif!important;
    padding:0 18px;
    margin:0;
    display:flex;
    align-items:center;
    justify-content:center;
}

.ui.positive.button{
    background:#29AD73!important;
}
.ui.negative.button{
    background:#D3D2DE!important;
    color:#000!important;
}
.ui.orange.button{
    background:#F15944!important;
}
.ui.basic.orange.button{
    min-width:60px;
    background:none!important;
    border-radius:3px;
    border:solid 1px #F15944!important;
    color:#F15944!important;
    box-shadow:none!important;
}
.ui.blue.button{
    background:#727AFF!important;
}
.ui.grey.button{
    background:#D3D2DE!important;
    color:#000!important;
}
.ui.grey.button.left{
    margin-left:0!important;
}

.ui.closeBtn {
    position: absolute;
    right: 15px;
    top: 15px;
    min-width:0!important;
    width: 13px;
    height: 13px!important;
    padding:0!important;
    background: none!important;
    opacity:1;
    cursor: pointer;
    z-index:10;
}
.ui.closeBtn:active{
    box-shadow:none!important;
}

.ui.closeBtn:before,
.ui.closeBtn:after{
    position: absolute;
    left: 7px;
    top: 0;
    content: '';
    height: 17px;
    width: 1.5px;
}

.ui.closeBtn:before{
    transform: rotate(45deg);
}

.ui.closeBtn:after{
    transform: rotate(-45deg);
}

.ui.closeBtn:hover{
    opacity: 0.8;
}

.paraHeading,
.titleModal,
.paraModal,
.paraTitle,
.pModal{
    width:100%; float:left;
    color:#000;
}
.pModal strong{
    font-weight:500;
}

.paraHeading{
    font:400 15px/20px 'Rubik',sans-serif;
    margin:0 0 20px;
}
b.paraHeading{
    font-weight:500;
}

.titleModal{
    font:500 13px 'Rubik',sans-serif;
    margin:0 0 20px;
}

.paraModal{
    font:400 13px/18px 'Rubik',sans-serif;
    margin:0 0 15px;
}
.dashedCover{
    margin:0 0 25px!important;
    padding:20px 15px;
    background:#F3F6FB;
    border:dashed 1.5px #727AFF;
}
.pModal{
    font:400 15px/22px 'Rubik',sans-serif;
    margin:0 0 15px;
}
.pModal a{
    color:#727AFF;
    text-decoration:underline;
}
.paraTitle{
    font:400 13px 'Rubik',sans-serif;
    margin:15px 0 10px;
}
.paraTitle:first-child{
    margin-top:0;
}

.contentGroup,
.contentGroup .content,
.extraContent{
    width:100%; float:left;
}
.contentGroup{
    margin:0 0 20px;
}

.extraContent{
    color:#000;
    font:400 13px 'Rubik',sans-serif;
    margin:8px 0 0;
}

.cgFlexible{
    display:flex;
    align-items:center;
}
.cgFlexible .content{
    width:auto;
    flex:1;
}
.contentGroup > p{
    font:400 15px 'Rubik',sans-serif;
    color:#000;
}

.defaultWrap{
    width:100%; float:left;
}
.defaultWrap.flexible{
    display:flex;
    align-items:center;
}

.displayImg{
    margin:20px 0;
}
.displayImg span.ui.image{
    margin-right:18px;
}

.hubDisplay > div{
    width:82px;
    height:82px;
    border-radius:0;
    background:#fff!important;
}
.hubDisplay > div > img{
    width:100%;
    height:100%;
}

.dashedBox{
    width:100%; float:left;
    background:#F8F7FA;
    border:dashed 1px #B8B5CE;
    border-radius:2px;
}

/***/

.ui.popup.popupBox{
    width:220px;
    padding:20px 15px;
    border:none;
    border-radius:3px;
    box-shadow:0 0 12px rgba(0,0,0,0.2);
}
.ui.popup.popupBox:before{
    box-shadow:none!important;
}
.ui.popup.popupBox > .ui.header,
.ui.popup.popupBox .alignerPopup > .ui.header{
    font:600 15px 'Rubik',sans-serif;
    border-bottom:solid 1px #D3D2DE;
    padding:0 0 10px;
    margin:0 0 5px;
}
.ui.popup.popupBox > p,
.ui.popup.popupBox .alignerPopup p{
    font:400 15px 'Rubik',sans-serif;
    margin:0 0 10px;
}

.ui.inputForm.searchDashboard{
    max-width:410px;
    margin:0 25px 0 0;
}
.ui.inputForm.searchDashboard > input{
    height:30px;
    background:#fff;
    border-radius:4px;
    border:none;
    font:400 13px 'Rubik',sans-serif;
    padding-left:10px;
}
.ui.iconFilter{
    font-size:12px!important;
}

.ui.popup.popupBox.hasPopup{
    padding:20px 0 5px;
}

.alignerPopup{
    width:100%;
    float:left;
    padding:0 15px;
}

/***/

.gap20{
    margin-top:20px;
    margin-bottom:20px;
}

.ui.button.uploadEditButton,
.ui.button.uploadModalOpen{
    background:none!important;
    padding:5px;
    font:400 15px 'Rubik',sans-serif;
    color:#727AFF!important;
}
.ui.button.uploadEditButton:hover{
    text-decoration:underline;
}

/** upload **/

/* For when you want a button to look like a link */
.linkButton {
    background: none!important;
    border: none;
    padding: 0!important;
    /*optional*/
    /* font-family: arial, sans-serif; */
    /*input has OS specific font-family*/
    color: #727AFF;
    text-decoration: underline;
    cursor: pointer;
}
.linkButton:hover {
    color: #1e70bf;
    text-decoration: none;
}
