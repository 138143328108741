.ui.modal.inviteUserModal{
    width:100%!important;
    max-width:700px;
}
.ui.modal.inviteUserModal > .actions{
    padding-top:0;
}
.ui.modal.inviteUserModal form{
    padding-right:1px;
}

.inviteUserPropertyBox{
    width:100%; float:left;
    padding:15px 5px 15px 15px;
    margin:10px 0 30px;
    background:#F3F6FB;
    border:dashed 1.5px #D3D2DE;
    display:flex;
    align-items:flex-start;
}
.inviteuserPropertyInner{
    width:100%; float:left;
    display:flex;
    align-items:flex-start;
}
.inviteUserPropertyBox .textGroup{
    flex:1.2;
    margin-bottom:0;
}
.inviteUserPropertyBox .textGroup:last-child{
    flex:2;
    position:sticky;
    top:0;
}
.inviteUserPropertyBox .textGroup p{
    margin:0 0 8px;
}
.inviteUserPropertyBox .textGroup p:last-child{
    margin:0;
}
.inviteUserModal .inputFormGroup > b, 
.inviteUserModal .textGroup > b{
    color:#000;
}

.inviteUserPropertyBox .textGroup b{
    position:sticky;
    top:0;
    background:#F3F6FB;
    margin-bottom:0!important;
    padding-bottom:9px;
}

.inviteInputGroup{
    width:100%; float:left;
    display:flex;
    align-items:center;
    justify-content:space-between;
}
.inviteInputGroup .textGroup{
    max-width:22%;
    margin:0;
}
.ui.buttonIcon.crossInvite{
    align-self:flex-start;
    margin:15px 15px 0 0!important;
}

.ui.popup.popupBox.deleteUserPopup{
    width:330px;
    max-width:330px;
}

.ui.popup.addUserPopup{
    width:280px!important;
    max-width:280px;
}

.ui.modal.inviteUserModal .content > .ui.checkbox.secondary{
    margin-bottom:20px;
}