.flexible.three{
    justify-content:space-between;
}
.flexible.three .ui.inputForm{
    max-width:200px;
    margin-right:20px;
}

.settingsLayout.accountSettings{
    padding:relative;
    padding-bottom:0;
}

.stickyActionGroup{
    width:100%;
    float:left;
    padding:15px 0;
    background:#fff;
    border-top:solid 1px #D3D2DE;
    position:sticky;
    bottom:0;
    left:0;
    z-index:1;
    visibility: hidden;
    opacity:0;
    transition: all ease 0.5s;

}
.stickyActionGroup.show{
    visibility: visible;
    opacity:1;
}
.stickyActionGroup:before,
.stickyActionGroup:after{
    content:'';
    width:35px;
    height:1px;
    border-top:solid 1.5px #D3D2DE;
    position:absolute;
    top:-1px;
    left:-35px;
}
.stickyActionGroup:after{
    left:auto;
    right:-35px;
}

.accountSettings .phoneBox{
    max-width:350px;
}

.productLanguage{
    width:100%; float:left;
}
.productLanguageBox{
    width:100%; float:left;
    background:#F3F6FB;
    padding:25px 30px;
}
.productLanguageRow{
    width:100%; float:left;
    display:flex;
    margin-bottom:5px;
}
.productLanguageColumn{
    font:400 13px 'Rubik',sans-serif;
    color:#000;
}

.productLanguageRow:first-child div{
    background:none;
    padding-top:0!important;
    padding-bottom:0!important;
}
.productLanguageRow:first-child .productLanguageColumn{
    font-size:12px;
    color:#667581;
    padding:0;
}
.productLanguageRow:first-child .productLanguageColumn:first-child{
    white-space:nowrap;
    padding-left:12px;
}
.productLanguageRow:first-child .productLanguageColumnRow{
    min-height:0;
}

.blackPara{
    color:#000!important;
    border-top:solid 1px #D3D2DE;
    padding:15px 0 0;
    margin:20px 0 0;
}

.productLanguageColumn:first-child{
    width:163px;
    min-width:100px;
    display:flex;
    align-items:center;
    background:#fff;
    border-radius:2px 0 0 2px;
    padding-left:12px;
}
.productLanguageColumn:last-child{
    flex:1;
    display:flex;
    flex-direction:column;
    margin-left:5px;
}

.productLanguageColumnRow{
    width:100%;
    min-height:70px;
    display:flex;
    align-items:flex-start;
    background:#fff;
    border-radius:0;
    margin-bottom:5px;
    padding:15px 0 15px 12px;
}
.productLanguageColumnRow:first-child{
    border-radius:0 2px 2px 0;
}
.productLanguageColumnRow:last-child{
    border-radius:0 2px 2px 0;
    margin-bottom:0;
}

.productLanguageItem{
    flex:1;
}
.productLanguageItem:last-child{
    text-align:center;
}
.productLanguageItem:last-child .ui.checkboxGroup{
    display:inline-block;
    float:none;
    width:auto;
    margin:0;
}

.disabledTitle{
    padding:0;
    position:relative;
    cursor:default;
    color:rgba(0,0,0,0.5);
}
.disabledTitle .ui.infoButton{
    position:absolute;
    right:-17px;
    top:-1px;
}

.pm2{margin:20px 0 40px!important;}

.flexedCloseAccount{
    width:100%; float:left;
    margin-top:-20px;
    display:flex;
    align-items:flex-start;
}
.bodyContent{
    flex:1;
    padding:20px 20px 20px 0;
}
.flexedCloseAccount > img{
    width:360px;
}

.ui.modal.routeLeavingModal{
    width:100%;
    max-width:400px!important;
    text-align:center;
    padding:50px!important;
}
.ui.modal.routeLeavingModal .pModal{
    font-size:17px;
    margin:0;
}
.ui.modal.routeLeavingModal > .actions{
    width:100%; float:left;
    display:flex;
    align-items:center;
    justify-content:center;
}
.ui.modal.routeLeavingModal > .actions .ui.button{
    margin:0 10px;
}

.currencyWrap .ui.selection.dropdown{
    max-width:500px;
    min-width:350px;
    min-height:0;
    height:25px;
    border-radius:0!important;
    border:none;
    border-bottom:solid 1px #D3D2DE;
    font:400 14px 'Rubik',sans-serif;
    padding:0 16px 0 0;
    position:relative;
}
.currencyWrap .ui.selection.dropdown>.dropdown.icon{
    position: absolute;
    right: 13px;
    top: 10px;
    width: auto;
    height: auto;
    padding: 0;
    font-size: 15px;
    color:#99a9c4;
}
.currencyWrap .ui.selection.dropdown.visible,
.currencyWrap .ui.upward.selection.dropdown.visible{
    box-shadow:0!important;
    border-radius:0!important;
    border-color:#727AFF;
}
.currencyWrap .ui.selection.dropdown .menu>.item{
    font:400 15px 'Rubik',sans-serif;
    padding:3px 10px!important;
    display:flex;
    align-items:center;
}
.currencyWrap .ui.selection.dropdown .menu>.item.active{
    font-weight:500;
}

/***/

.ui.modal.redirectingModal{
    padding:40px!important;
}
.ui.modal.redirectingModal .aligner .header{
    border:none!important;
    margin:0!important;
    padding:0!important;
}
.ui.modal.redirectingModal .content{
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
}
.redirectSpinner{
    background:url(./spinner-loader.gif) no-repeat center;
    width:50px;
    height:50px;
    display:flex;
    align-items:center;
    justify-content:center;
    font:400 13px 'Rubik',sans-serif;
    color:#000;
    margin:0 0 15px;
}

/*** developers ***/

.videoEmbedCover{
    width:100%; float:left;
    display:flex;
    align-items:flex-start;
    position:relative;
}

.videoEmbedBox{
    background:#F4F7FC;
    border:dashed 1px #D3D2DE;
    border-radius:3px;
    position:relative;
    padding:0;
}
.videoEmbedCover .videoEmbedBox > .ui.buttonIcon{
    position:absolute;
    z-index:2;
    right:15px;
    top:15px;
}
.videoEmbedBox .ui.placeholder{
    max-width:100%;
}
.videoEmbedCover .videoEmbedBox .ui.placeholder{
    margin:0 0 8px!important;
}


.videoEmbedCover .videoEmbedBox{
    flex:1;
    overflow:auto;
}
.videoEmbedCover .videoEmbedBox pre{
    padding:20px;
    font:400 12px 'Rubik',sans-serif;
}

.ui.button.buttonIcon.webCopyButton{
    position:absolute;
    top:10px;
    right:340px;
    z-index:10;
}

.videoWrap{
    width:300px;
    display:inline-flex;
    flex-direction:column;
}

.videoThumb{
    width:100%; float:left;
    height:200px;
    background:#fff;
    border-radius:3px;
    border:solid 1px #D3D2DE;
    display:flex;
    align-items:center;
    justify-content:center;
}
.videoWrap p{
    font:400 13px/17px 'Rubik',sans-serif;
    color:#667581;
    margin:8px 0 0;
}

.videoEmbedCover .videoWrap{
    margin-left:20px;
}
.videoThumb > iframe{
    width:300px;
}

.appRow{
    width:100%; float:left;
    margin:40px 0 0;
    display:flex;
    align-items:flex-start;
    justify-content:space-between;
    flex-wrap:wrap;
}
.appRowBody{
    flex:1;
    padding:0 20px 0 0;
}
.appRowColumn{
    min-width:200px;
}


.appSubRow{
    width:100%; float:left;
    display:flex;
    align-items:flex-start;
}

.appSubRow .ui.search.dropdownGroup{
    background:#F4F7FC;
    border:none;
}
.appSubRow .ui.search.dropdownGroup .icon.caret{
    position:absolute;
    top:12px;
    right:10px;
    margin:0;
}
.appSubRow .ui.orange.basic.button{
    margin-top:2px;
}


.appRow h6.ui.header{
    width:100%;
    margin:0 0 25px;
}

.appRow .textGroup{
    margin:0 0 10px;
}
.appRow .textGroup > b{
    font-size:16px;
}
.appRow .textGroup > p{
    margin-bottom:4px;
}

.appSubRow .textGroup:nth-child(2){
    margin-left:15px;
}

.appRow .textGroup .ui.input{
    width:100%;
}
.appRow .textGroup .ui.input>input{
    width:100%; float:left;
    min-height:0;
    height:40px;
    padding:0 10px;
    border-radius:3px;
    border:none;
    background:#f4f7fc!important;
    font:400 15px 'Rubik',sans-serif;
    color:#000;
}

.ui.button.buttonIcon.buttonDownloadLicence{
    float:left;
    width:120px;
    height:40px;
    margin:0;
    padding:5px 5px 5px 10px;
    background: #F4F7FC!important;
    border: dashed 1px #D3D2DE;
    border-radius: 3px;
    color:#000!important;
}
.ui.button.buttonIcon.buttonDownloadLicence img{
    width:16px;
    margin-left:auto;
    order:2;
}

.appLinkItem{
    margin:0 40px 0 0;
    font:400 15px 'Rubik',sans-serif;
    color:#000;
    display:inline-flex;
    align-items:center;
}
.appLinkItem img:first-child{
    margin:0 8px 0 0;
}
.appLinkItem img:last-child{
    margin:0 0 0 8px;
}




.mobileVideoWrap .videoEmbedBox{
    padding:12px 10px 12px 15px;
    display:flex;
    align-items:center;
    margin:0 0 15px;
}
.mobileVideoWrap .videoEmbedBox .ui.placeholder{
    flex:1;
}
.mobileVideoWrap .videoEmbedBox .ui.buttonIcon{
    margin:0 0 0 20px;
    right:auto;
    top:auto;
}
