div.ui.dropdownGroup{
    width:100%; float:left;
    min-height:0;
    height:30px;
    padding:0;
    border:none;
    border-radius:0;
    border-bottom:solid 1px #D3D2DE;
    font:400 15px 'Rubik',sans-serif;
    color:#000;
    background:none;
}
div.ui.dropdownGroup > .text{
    color:#000;
}
div.ui.dropdownGroup > .icon{
    float:right;
    color:#939CA3;
    width:auto;
    margin:5px 5px 0 0;
}
div.ui.dropdownGroup > img{
    float:right;
    color:#939CA3;
    width:auto;
    margin:5px 5px 0 0;
}
div.ui.dropdownGroup >.menu{
    width:100%;
    border-radius:0 0 4px 4px;
}

div.ui.dropdownGroup .menu .active.item{
    font-weight:500;
}