.ui.modal.addTeamModal{
    width:100%!important;
    max-width:656px;
}
.ui.modal.addTeamModal > .actions{
    padding-top:0;
}
.inputArea{
    width:100%;
    float:left;
}
.inputArea input{
    width:100%;
    height:40px;
    border-radius:0!important;
    border-width:0 0 1px!important;
    padding:0 0 5px!important;
    border-bottom:solid 1px #D3D2DE;
    font:400 15px 'Rubik',sans-serif!important;
    color:#333!important;
}