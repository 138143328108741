.textGroup{
    width:100%;
    float:left;
    margin:0 0 15px;
}

.textGroup > p{
    font:400 13px 'Rubik',sans-serif;
    margin:0;
}
.textGroup > p,
.textGroup > .textGroupContent,
.textGroup > b{
    width:100%; float:left;
    font:400 13px 'Rubik',sans-serif;
    color:#000;
}
.textGroup > b{
    font:600 15px 'Rubik',sans-serif;
    margin:0 0 6px;
}

.textGroup strong{
    font-weight:500;
}
.textGroup strong span{
    font-weight:400;
}

.textGroup .ui.dropdownGroup{
    height:25px;
    line-height:23px;
}

.tgFlexible{
    display:flex;
    align-items:center;
}
.tgFlexible > p {
    width:auto;
    min-width:80px;
    padding-right:5px;
}
.tgFlexible > .textGroupContent{
    width:100%;
    flex:1;
}

.extraDetail{
    width:100%; float:left;
    margin:5px 0 0;
    font:400 13px/18px 'Rubik',sans-serif;
    color:#000;
}