div.ui.checkboxGroup{
    width:100%; float:left;
    margin:0 0 10px;
}

div.ui.checkbox label,
div.ui.checkbox.radio label{
    font:400 13px 'Rubik',sans-serif;
    color:#000;
    line-height:18px;
}

div.ui.checkbox label:before{
    top:-1px;
}

div.ui.checkbox label:before,
div.ui.checkbox.radio label:before{
    width:18px;
    height:18px;
    border-radius:2px;
    border-color:#D3D2DE!important;
    opacity:1!important
}

div.ui.checkbox label:hover:before{
    border-color:#727AFF!important;
}

div.ui.checkbox:not(.radio) input:checked~label:before{
    background:url(./icon-checkbox.svg) no-repeat center #727AFF!important;
    /* background:url(/img/icon-checkbox.svg) no-repeat center #727AFF!important; */
    border-color:#727AFF!important;
}

div.ui.checkbox:not(.radio) label:after{
    display:none!important;
}

div.ui.checkbox.radio label:before{
    border-radius:100px;
    top:0;
}

div.ui.checkbox.radio label:after{
    width:22px;
    height:22px;
    background:#727AFF!important;
    top:-2px;
    left:-2px;
}
div.ui.checkbox.radio input:checked~label:before{
    background:#fff!important;
    border:solid 1px #727AFF!important;
}
div.ui.checkbox.radio input:checked~label:after{
    background:#727AFF!important;
}

.ui.checkbox.secondary label:before{
    border-color:#D3D2DE;
}
.ui.checkbox.secondary label:hover:before{
    border-color:#F15944!important;
}

.ui.checkbox.secondary:not(.radio) input:checked~label:before{
    background:url(./icon-checkbox-secondary.svg) no-repeat center #fff!important;
    border-color:#F15944!important;
}
.ui.checkbox.secondary:not(.radio) input:checked~label:after{
    display:none;
}

.defaultCB input[type='checkbox']{
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 17px;
    width:17px;
    max-width:17px;
    outline: none;
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin: 0;
    cursor: pointer;
    border: 1px solid #D3D2DE;
    border-radius:2px;
}
.defaultCB input[type='checkbox']:after{
    content: '';
    display: block;
    left: 0;
    top: 0;
    position: absolute;
}
.defaultCB input[type='checkbox']:checked{
    border-color:#F15944;
    background:url(./icon-checkbox-secondary.svg) no-repeat center #fff!important;
}
.defaultCB input[type='checkbox']:disabled{
    cursor: not-allowed;
    opacity: 0.9;
}
.defaultCB input[type='checkbox']:disabled:checked{
}
.defaultCB input[type='checkbox']:disabled + label{
    cursor: not-allowed;
}
.defaultCB input[type='checkbox']:hover:not(:checked):not(:disabled){
    
}
.defaultCB input[type='checkbox']:focus{
   
}
.defaultCB input[type='checkbox']:not(.switch){
    
}
