.ui.header.securityBreadCrumb{
    width: 100%;
    float: left;
    font: 600 18px 'Rubik',sans-serif!important;
    color: #000;
    margin:0 0 10px;
    display:flex;
    align-items:center;
}
.ui.header.securityBreadCrumb > .ui.button{
    border-radius:0;
    border:none;
    background:none;
    min-height:0;
    padding:0;
    font:400 18px 'Rubik',sans-serif!important;
    color:#727AFF;
    position:relative;
    margin:0 30px 0 0;
}
.ui.header.securityBreadCrumb > .ui.button:after{
    content:'';
    background:url(./icon-chevron-down.svg) no-repeat;
    width:11px;
    height:10px;

    position:absolute;
    right:-22px;
    top:6px;
    transform:rotate(-90deg);
    filter: brightness(0.8);
    pointer-events:none;
}
.ui.header.securityBreadCrumb > .ui.button:hover{
    text-decoration:underline;
}

.securityWrapper{
    max-width:100%;
}
.securityTabsCover {
    width: 100%;
    float: left;
    padding: 0 0 20px;
    margin: 0 0 20px;
}

.ui.button.securityTabsItem{
    width:100%; float:left;
    min-height:60px;
    background:#fff!important;
    border:solid 1px #D3D2DE;
    border-radius:2px;
    padding:0 20px;
    margin:0 0 5px;
    position:relative;

    font:500 15px 'Rubik',sans-serif;
    color:#000;

    display:flex;
    flex-direction:column;
    justify-content:center;
    text-align:left;
}
.ui.button.securityTabsItem span{
    font-size:11px;
    font-weight:400;
    color:#667581;
}
.ui.button.securityTabsItem i.icon{
    position:absolute;
    right:20px;
}
.ui.button.securityTabsItem:hover{
    background:#F1F1F1!important;
}

/*** session configuration ***/

.chooseConfigurationGroup{
    width:100%; float:left;
    display:flex;
    align-items:center;
    margin:0 0 15px;
}
.chooseConfigurationItem{
    width:198px;
    margin:0 23px 0 0;
    display:flex;
    flex-direction:column;
}
.chooseConfigurationItem:last-child{margin-right:0;}
.chooseConfigurationItem .ui.checkbox.radio label{
    font:400 15px 'Rubik',sans-serif;
}
.chooseConfigurationItem .ui.checkbox.radio input:checked~label:before{
    border-color:#F15944!important;
}
.chooseConfigurationItem .ui.checkbox.radio input:checked~label:after{
    background:#F15944!important;
}
.chooseConfigurationBox{
    height:100px;
    border-radius:3px;
    border:solid 1px #D3D2DE;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    font:400 15px 'Rubik',sans-serif;
    color:#667581;
    margin:10px 0 0;
}
.chooseConfigurationBox span{
    margin-top:4px;
}

.chooseConfigurationItem.active .chooseConfigurationBox{
    border-color:#F15944;
}

.tgExpiration{
    max-width:400px;
    display:flex;
    align-items:flex-start;
}
.tgExpiration > p{
    width:auto;
    margin:0 10px 0 0;
}
.tgExpiration .textGroupContent{
    flex:1;
}
.tgExpiration .textGroupContent .ui.input{
    width:100%;
}
.tgExpiration .textGroupContent .ui.input>input{
    border-radius:0;
    border:none;
    border-bottom:solid 1px #D3D2DE;
    padding:5px 0;
    font:400 15px 'Rubik',sans-serif;
}
.tgExpiration .textGroupContent .ui.input>input::placeholder{
    color:#667581;
}
.tgExpiration .textGroupContent span{
    width:100%; float:left;
    font:400 13px 'Rubik',sans-serif;
    color:#667581;
    margin:5px 0 0;
}

/*** two step verification ***/

.ui.toggle.checkbox.toggleStepVerification{
    margin:0 10px 0 0;
}
.ui.toggle.checkbox.toggleStepVerification label{
    font-size:15px;
}
.ui.toggle.checkbox.toggleStepVerification label:before{
    background:#C0CBDA!important;
    width:45px;
    height:23px;
}
.ui.toggle.checkbox.toggleStepVerification label:after{
    display:block!important;
    background:#fff;
    box-shadow:none!important;
    top:1px;
    left:1px;
    width:21px;
    height:21px;
}
.ui.toggle.checkbox.toggleStepVerification input:checked~label:before,
.ui.toggle.checkbox.toggleStepVerification input:focus:checked~label:before{
    background:#F15944!important;
}
.ui.toggle.checkbox.toggleStepVerification input:checked~label:after{
    left:23px;
}

.stepVerificationWrap{
    width:100%; float:left;
    background:#F3F6FB;
    padding:0 10px 20px;
}
.tableBoxVerification{
    padding:0;
    margin:0;
    min-height:0!important;
    margin:0 0 20px;
}
.tableBoxVerification .tableBoxColumn{
    min-width:50%;
    max-width:50%;
    flex-direction:row;
    justify-content:flex-start;
    align-items:center;
}
.tableBoxVerification .tableBoxColumn .defaultWrap{
    display:flex;
    align-items:center;
}
.tableBoxVerification .tableBoxColumn .defaultWrap .ui.checkbox{
    margin:0 10px 0 0;
}
.tableBoxVerification .tableBoxRow:first-child .tableBoxColumn{
    padding-bottom:5px;
}
.stepVerificationWrap .displayText{
    top:7px;
}

.ui.modal.verificationModal{
    padding-left:0;
    padding-right:0;
}
.ui.modal.verificationModal .aligner{
    padding:0 38px;
}
.ui.modal.verificationModal > .actions{
    padding:30px 38px 0;
}

.ui.modal.verificationModal .textGroup > b{
    font-size:15px;
}

.listingOrange{
    width:100%; float:left;
    margin:0;
    padding:0;
    list-style:none;
}
.listingOrange li{
    width:100%; float:left;
    font:400 15px 'Rubik',sans-serif;
    color:#000;
    position:relative;
    padding:0 0 0 20px;
    margin:0 0 20px;
}
.listingOrange li:before{
    content:'';
    background:#F15944;
    width:9px;
    height:9px;
    border-radius:100px;
    position:absolute;
    left:0;
    top:5px;
}

.applicationSetupCover{
    width:100%; float:left;
    display:flex;
    border:solid 1px #C0CBDA;
    border-radius:3px;
}
.applicationSetupItem{
    width:50%;
    min-width:50%;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    padding:20px;
}
.applicationSetupItem:first-child{
    box-shadow:inset -1px 0 0 #C0CBDA;
}
.codeApplicationVerification{
    width:100%;
    height:37px;
    border-radius:3px;
    border:dotted 2px #C0CBDA;
    background:#F3F6FB;
    font:600 15px 'Rubik',sans-serif;
    display:flex;
    align-items:center;
    justify-content:center;
    margin:20px 0 4px;
}
.applicationSetupItem span{
    font:400 15px 'Rubik',sans-serif;
}

.verificationModalCode{
    margin:20px 0 0;
}
.verificationModalCode > p{
    width:auto;
}
.verificationModalCode .textGroupContent{
    flex:1;
    display:flex;
    align-items:center;
}
.verificationModalCode .textGroupContent .ui.input{
    flex:1;
    padding:0 15px 0 0;
}
.verificationModalCode .ui.input>i.icon{
    color:#29AD73;
    opacity:1!important;
}
.verificationModalCode .ui.input>input{
    border-radius:0;
    border:none;
    border-bottom:solid 1px #D3D2DE;
    height:26px;
    padding:0;
    color:#000;
    font:400 15px 'Rubik',sans-serif;
}
.verificationModalCode .ui.input>input::placeholder{
    color:#667581;
}
.verificationModalCode .textGroupContent .ui.button.orange{
    min-width:100px;
}

/*** saml single sign ***/

.toggleSingleSignWrap{
    width:100%; float:left;
    display:flex;
    align-items:flex-start;
}
.sectionSettings h3{
    font:600 16px 'Rubik',sans-serif;
}
.toggleSingleSignWrap p{
    display:flex;
    flex-direction:column;
    font:400 15px 'Rubik',sans-serif;
}
.toggleSingleSignWrap p span{
    margin:10px 0 0;
    font-size:13px;
}
.toggleSingleSignWrap p span strong{
    font-weight:500;
}
.securityButtonsGroup{
    width:100%; float:left;
}
.securityButtonsGroup .ui.button{
    float:left;
    margin:0 10px 10px 0!important;
}

/*** managed domains ***/

.tabManaged{
    width:100%; float:left;
    padding:20px 0 20px;
}
.tabManaged .ui.secondary.menu{
    width:100%; float:left;
    border-bottom:solid 1px #D3D2DE!important;
    box-shadow:none;
    min-height:0;
    margin:0;
}

.tabManaged .ui.secondary.menu .item{
    font:400 15px/16px 'Rubik',sans-serif;
    color:#000;
    padding:5px 0!important;
    margin:0 30px -1px 0!important;
    border-radius:0!important;
    background:none!important;
    border:none!important;
}

.tabManaged .ui.secondary.menu .active.item{
    box-shadow:inset 0 -3px 0 0 #727AFF!important;
    font-weight:400!important;
}

.tabManaged > .ui.segment{
    width:100%; float:left;
    margin:0!important;
    padding:20px 0!important;
    border:none!important;
    background:none;
}

.para{
    width:100%; float:left;
    margin:0 0 20px;
    font:400 13px 'Rubik',sans-serif;
    color:#000;
}
.para strong{
    font-weight:600;
}
.dnsCodeWrap{
    width:100%; float:left;
    display:flex;
    flex-direction:column;
    margin:0 0 20px;
}
.dnsCodeWrap > *{
    max-width:410px;
}
.tabManaged .dnsCode{
    max-width:640px;
}

.dnsCode{
    display:flex;
    align-items:center;
    margin:0 0 10px;
}
.dnsCode > .ui.button.orange.basic{
    min-width:65px;
    margin-left:15px;
}

.urlcodebox{
    height:36px;
    background:#F3F6FB;
    border:dotted 2px #C0CBDA;
    border-radius:3px;
    font:400 15px 'Rubik',sans-serif;
    padding:7px 10px 0;
    white-space:nowrap;
    max-width:100%;
    overflow:hidden;
    text-overflow:ellipsis;
}

.paraSmall{
    width:100%;
    font:400 13px 'Rubik',sans-serif;
    color:#667581;
}

.tableBoxDNS{
    min-height:0!important;
    margin-top:5px;
}
.tableBoxDNS .tableBoxRow:first-child .tableBoxColumn{
    padding-bottom:5px;
}
.tableBoxDNS .tableBoxColumn{
    flex-direction:row;
    align-items:center;
    justify-content:flex-start;
    min-height:50px;
    padding:8px 10px 8px 15px;
}
.tableBoxDNS .tableBoxColumn:first-child{
    min-width:30%;
}
.tableBoxDNS .tableBoxColumn:nth-child(2){
    min-width:20%;
}
.tableBoxDNS .tableBoxColumn:last-child{
    min-width:50%;
}
.statusDNS{
    display:inline-flex;
    align-items:center;
}
.statusDNS:before{
    content:'';
    margin:0 10px 0 0;
    min-width:10px;
    width:10px;
    height:10px;
    border-radius:100px;
    background:#F15944;
}
.statusDNS.active:before{
    background:#29AD73;
}
.actionsDNS{
    display:inline-flex;
    align-items:center;
}
.actionsDNS .ui.button.orange.basic{
    min-width:70px;
}
.actionsDNS .ui.button.buttonIcon{
    margin-left:15px;
}

/*** saml identity provider ***/

.tgIdentityProvider > b{
    font-size:13px;
}
.identityProviderFlex{
    margin:0 0 30px;
}
.identityProviderFlex > .content{
    display:flex;
}
.idtentityProviderColumn{
    width:50%;
    padding-right:40px;
}

.idtentityProviderColumn .textGroup > b{
    font-size:15px;
}
.idtentityProviderColumn .textGroup .ui.input{
    width:100%;
}
.idtentityProviderColumn .textGroup .ui.input>input{
    border-radius:0;
    border:none;
    border-bottom:solid 1px #D3D2DE;
    padding:0;
    height:25px;
    font:400 15px 'Rubik',sans-serif;
}
.idtentityProviderColumn .textGroup .ui.input>input::placeholder{
    color:#000;
}
.idtentityProviderColumn > .textGroup:last-child{
    margin:0;
}

.urlcode{
    width:100%; float:left;
    height:32px;
    padding:6px 10px 0;
    border-radius:3px;
    background:#F3F6FB;
    font:400 15px 'Rubik',sans-serif;
    color:#667581;
    max-width:100%;
    overflow:hidden;
    white-space:nowrap;
    text-overflow:ellipsis;
}

.urlcodecover{
    width:100%; float:left;
    min-height:32px;
    padding:6px 10px 0;
    border-radius:3px;
    background:#F3F6FB;
    font:400 15px 'Rubik',sans-serif;
    color:#667581;
    margin:0 10px 0 0;
    word-break:break-all;
}
.urlcodeWrap .urlcodecover{
    font-size:13px;
} 
.urlcodeWrap > .textGroupContent > .ui.buttonIcon{
    align-self:flex-start;
}

.urldetailbox{
    width:100%; float:left;
    border-radius:3px;
    background:#F3F6FB;
    font:400 15px 'Rubik',sans-serif;
    color:#667581;
    padding:10px;
}

.idtentityProviderBoxColumn{
    width:50%;
    border-radius:3px;
    border:solid 1px #C0CBDA;
    padding:15px 18px;
}
.idtentityProviderBoxColumn > .ui.header{
    width:100%; float:left;
    font:500 15px 'Rubik',sans-serif;
}
.idtentityProviderBoxColumn .textGroup{
    margin:0 0 15px;
}
.idtentityProviderBoxColumn .textGroup > p{
    margin:0 0 5px;
}
.idtentityProviderBoxColumn .textGroup > .textGroupContent{
    display:flex;
    align-items:center;
}
.idtentityProviderBoxColumn .textGroup .urlcode{
    margin-right:20px;
}

.identityChooseGroup{
    width:100%; float:left;
    margin:20px 0;
}
.checkboxIdentityProvider{
    width:100%; float:left;
    margin:0 0 20px;
    display:flex;
    align-items:flex-start;
}
.checkboxIdentityProvider .defaultWrap{
    width:auto;
    margin:2px 15px 0 0;
}
.checkboxIdentityProvider .defaultWrap .ui.checkbox{
    margin:0;
}
.checkboxIdentityProvider p{
    font:400 15px 'Rubik',sans-serif;
    color:#000;
}
.checkboxIdentityProvider p span{
    display:block;
    font-size:13px;
    color:#667581;
    margin:10px 0 0;
}

.idtentityProviderColumn .textGroup .ui.form textarea{
    min-height:400px;
    font:400 12px monospace,monospace;
    text-align:justify;
}
.idtentityProviderColumn .textGroup .ui.input input::placeholder{
    color:#999!important;
}

.tgCertificate{
    width:auto;
}
.tgCertificate > p{
    min-width:20px;
    width:auto;
    margin:0 10px 0 0!important;
}
.tgCertificate .ui.buttonIcon{
    white-space:nowrap;
}
.tgCertificate .ui.buttonIcon img{
    order:2;
    width:16px;
    margin:0 0 0 10px;
}

/*** ip range ***/

.tgRangeRestrictions{
    margin:25px 0 10px;
}
.tgRangeRestrictions > p{
    width:auto;
    margin-right:10px;
}
.tgRangeRestrictions .textGroupContent{
    max-width:130px;
}
.tgRangeRestrictions .textGroupContent .urlcodebox{
    text-align:center;
}

.contentIPRange{
    position:relative;
}
.ui.button.buttonIPRange{
    min-width:80px!important;
    position:absolute;
    right:0;
    top:0;
}
.contentIPRange h5.ui.header{
    margin:10px 0 30px!important;
}

.ipRangeGroup{
    width:100%; float:left;
    display:flex;
    flex-direction:column;
}
.ipRangeItem{
    width:100%; float:left;
    margin:0 0 15px;
    display:flex;
    align-items:center;
}
.ipRangeItem > img{
    margin:0 8px;
}
.ipRangeItem .ui.input{
    width:200px;
}
.ipRangeItem .ui.input>input{
    width:100%;
    border-radius:0;
    border:none;
    border-bottom:solid 1px #D3D2DE;
    padding:0;
    height:28px;
    font:400 15px 'Rubik',sans-serif;
    color:#000;
}
.ipRangeItem .ui.input>input::placeholder{
    color:#7F7F7F;
}
.ipRangeItem > .ui.buttonIcon:last-child{
    margin-left:10px;
}

/*** audit logs ***/

.auditLogsLayout{
    padding:0;
}
.auditLogsWrapper{
    width:100%; float:left;
    padding:30px 0;
    background:#F3F6FB;
    position:relative;
}
.auditLogsWrapper:before,
.auditLogsWrapper:after{
    content:'';
    width:35px;
    height:100%;
    background:#F3F6FB;
    position:absolute;
    top:0;
    left:-35px;
}
.auditLogsWrapper:after{
    left:auto;
    right:-35px;
}

.auditLogsTable{
    width:100%; float:left;
    margin:15px 0 20px;
    padding:0;
}

.auditTableRow{
    min-width:100%;
    float:left;
    display: flex;
    padding:0;
    border-radius:4px;
    margin:0 0 5px;
    background:#fff;
}

.auditTableRow:first-child{
    background:none;
}
.auditTableRow:first-child .auditTableColumn{
    padding:10px 10px 10px 15px;
    min-height:0;
}
.auditTableRow:first-child .auditTableColumn > p{
    font:400 12px 'Rubik',sans-serif;
    color:#667581;
}

.auditTableColumn{
    flex:1;
    min-width:200px;
    max-width:400px;
    min-height:40px;
    display:flex;
    align-items: center;
    padding:15px 10px 15px 15px;
    position:relative;

    font:400 15px 'Rubik', sans-serif;
    color:#000;
}

.auditTableColumn:last-child{
    padding-right:50px!important;
}
.auditTableColumn > img{
    height:26px;
    margin-left:10px;
}

.auditTableColumn .ui.buttonIcon{
    margin:0;
    position:absolute;
    right:30px;
}
.auditTableColumn .ui.buttonIcon img{
    width:14px;
    height:18px;
}

/*** user based configuration ***/

.sectionSettingsConfiguration{
    padding-bottom:20px;
}

.userBasedConfiguration{
    width:100%; float:left;
    margin:25px 0 0;
}
.tableboxCover{
    width:100%; float:left;
    background:#F3F6FB;
    padding:10px;
}
.tableBoxUserBased{
    min-height:100px!important;
    margin:0 0 15px;
}
.tableBoxUserBased .tableBoxRow:first-child .tableBoxColumn:first-child{
    /* padding:0 0 5px 45px; */
    min-width:35%;
}
.tableBoxUserBased .tableBoxRow:first-child .tableBoxColumn{
    padding:0 10px 5px 15px;
}
.tableBoxUserBased .tableBoxColumn{
    flex-direction:row;
    align-items:center;
    justify-content:flex-start;
    min-height:50px;
    padding:12px 10px 12px 15px;
}
.tableBoxUserBased .tableBoxColumn:first-child{
    min-width:35%;
}
.tableBoxUserBased .tableBoxColumn:nth-child(2){
    min-width:30%;
}
.tableBoxUserBased .tableBoxColumn:last-child{
    min-width:35%;
}

.checkboxUserBased{
    width:100%; float:left;
    display:flex;
    align-items:center;
}
.checkboxUserBased .ui.checkbox{
    margin:0 10px 0 0;
}
.checkboxUserBased p{
    font:400 15px 'Rubik',sans-serif;
}
.checkboxUserBased p span{
    display:block;
    color:#969DB8;
    font-size:14px;
}
.ui.input.formControl{
    width:100%;
}
.ui.input.formControl>input{
    border-radius:0;
    font:400 15px 'Rubik',sans-serif;
    padding:5px 0;
    border:none;
    border-bottom:solid 1px #D3D2DE;
}
.ui.input.formControl>input::placeholder{
    color:#667581;
}

.sessionConfigWrapper{
    width:100%; float:left;
    max-width:690px;
}

.tableBoxUserBased .tableBoxColumn .ui.placeholder{
    width:100%; float:left;
    height:18px;
    border-radius:2px;
}
.placeholderUserBased{
    width:100%; float:left;
}

.userBasedConfiguration .ui.pagination.menu.paginationDashboard{
    margin-right:auto;
}