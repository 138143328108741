.dividerLine{
    float:left;
    width:100%;
    height:1px;
    border-bottom:solid 1px #D3D2DE;
    margin:5px 0;

}

.dividerVertical{
    width:auto;
    height:18px;
    width:1px;
    margin:0 5px;
    border-right:solid 1px #C0CBDA;
}

.isEmpty{
    border:none;
    margin:10px 0;
}