.editTeamActionGroup{
    width:100%; float:left;
}
.editTeamProfileItem{
    width:100%; float:left;
    margin:0 0 6px;
    display:flex;
    align-items:center;
}
.editTeamProfileItem > span.ui.image{
    min-width:30px;
    width:30px;
    height:30px;
    margin:0 5px 0 0;
    border-radius:30px;
    overflow:hidden;
}
.editTeamProfileItem span.ui.image img{
    max-width:100%;
    float:left;
}
.editTeamProfileItem p{
    flex:1;
    margin:0;
    font:400 15px 'Rubik',sans-serif;
    color:#000;
}

.descriptionBorder{
    border-bottom:solid 1px #D3D2DE;
    padding:0 0 20px;
}

.ui.header.headingTitle.teamHeading{
    padding-right:0;
}

.editTeamProfileItem.empty{
    background:#F3F6FB;
    border:dashed 1.5px #D3D2DE;
    margin:15px 0;
    padding:15px 10px 15px 20px;
}
.editTeamProfileItem.empty > img{
    margin:0 15px 0 0;
}
.editTeamProfileItem.empty p{
    color:#667581;
    font:400 13px 'Rubik',sans-serif;
}
.editTeamProfileItem.empty p strong{
    display:block;
    font-weight:600;
    margin:0 0 3px;
}

.addUserItem{
    width:100%; float:left;
    margin:0 0 10px;
    display:flex;
    align-items:center;
    font:400 15px 'Rubik',sans-serif;
    color:#000;
}
.addUserItem .ui.image{
    width:30px;
    height:30px;
    border-radius:30px;
    margin:0 10px;
    background:#F3F6FB;
    overflow:hidden;
}
.imgUserItem img{
    max-width:100%;
}

.ui.popup.addUserPopup p{
    font-size:13px;
    margin:15px 0 25px;
}
.ui.popup.addUserPopup p .ui.buttonIcon{
    text-decoration:underline;
}

.ui.popup.addUserPopup > .ui.button{
    border-radius:3px!important;
    font-weight:400!important;
    font-size:12px!important;
    min-width:60px;
    height:30px;
    margin:0 20px 10px 0;
}