div.ui.inputForm,
.ui.inputForm{
    width:100%; float:left;
    margin:0 0 20px;
}
div.ui.inputForm > input,
.ui.inputForm > input{
    width:100%; float:left;
    min-height:0;
    height:30px;
    padding:0;
    border-radius:0;
    border:none;
    border-bottom:solid 1px #D3D2DE;
    font:400 15px 'Rubik',sans-serif;
    color:#000;
}
.ui.inputForm > textarea{
    width:100%; float:left;
    height:70px;
    padding:0;
    border-radius:0;
    border:none;
    border-bottom:solid 1px #D3D2DE;
    font:400 15px 'Rubik',sans-serif;
    color:#000;
}
.ui.inputForm > textarea::placeholder{
    letter-spacing: -.5px;
}

div.ui.inputForm.darkText > input::placeholder,
.ui.inputForm.darkText > input::placeholder{
    color:#000!important;
}
div.ui.inputForm > input::placeholder,
.ui.inputForm > input::placeholder,
.ui.inputForm > textarea::placeholder,
.editUserModalSidebar .ui.input.inputForm > input::placeholder{
    color:rgb(178,186,192)!important;
}

div.ui.icon.inputForm>input,
.ui.icon.inputForm>input{
    padding-right:25px!important;
}

div.ui.inputForm > img,
.ui.inputForm > img{
    position:absolute;
    right:0;
    top:5px;
}