.tableBoxRestrictedAccess .tableBoxColumn{
    width:10%;
    min-width:10%;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    padding:5px 10px!important;
}
.tableBoxRestrictedAccess .tableBoxColumn:first-child{flex:1;width:30%; min-width:30%;}
.tableBoxRestrictedAccess .tableBoxColumn:last-child{width:12%;min-width:12%;max-width:14%;padding:5px 5px 5px 0!important;}


.disableMode{
    color:darkgrey
}

.fieldsContainer{
    display: flex;
    align-items: center;
}

.fieldsContainer > div:not(:first-child) {
    margin-left: 30px; /* Adjust the margin value as needed */
}

input[type="checkbox"] {
    width: 20px; /* Adjust width as needed */
    height: 20px; /* Adjust height as needed */
}

.modules-dropdown, .normal-input{
    width: 250px;
}


.apiKeyWidth p{
    max-width: 225px;
}