a.ui.button.buttonIcon,
button.ui.button.buttonIcon,
.ui.buttonIcon{
    width:auto;
    border-radius:0;
    padding:0;
    margin:0;
    border:none;
    box-shadow:none;
    background:none;
    color:#727AFF;
    font:400 15px 'Rubik',sans-serif;
    display:inline-flex;
    align-items:center;
}
a.ui.button.buttonIcon:hover,
button.ui.button.buttonIcon:hover,
.ui.buttonIcon:hover,
a.ui.button.buttonIcon:focus,
button.ui.button.buttonIcon:focus,
.ui.buttonIcon:focus{
    background:none;
    color:#727AFF;
    box-shadow:none;
}

a.ui.button.buttonIcon img,
button.ui.button.buttonIcon img,
.ui.buttonIcon img{
    margin-right:5px;
}

a.ui.button.buttonIcon:hover,
button.ui.button.buttonIcon:hover,
.ui.buttonIcon:hover{
    text-decoration:underline;
}
