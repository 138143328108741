.ui.modal.uploadCSVModal{
    padding-left:0;
    padding-right:0;
}
.ui.modal.uploadCSVModal .actions{
    padding:35px 38px 0;
}

.dropFileBox{
    width:100%;
    height:195px;
    padding:20px 15px;
    background:#F3F6FB;
    border:dashed 1.5px #727AFF;
    border-radius:3px;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    color:#667581;
    font:400 15px 'Rubik',sans-serif;
}
.dropFileBox strong{
    font-weight:600;
}
.dropFileBox span{
    margin:10px 0;
}

.tableBoxCSV{
    background:#F3F6FB;
    padding:15px 38px 30px;
}
.tableBoxCSV .tableBoxRow:not(.tableBoxRow-head){
    border:solid 1px #D3D2DE;
}
.tableBoxCSV .tableBoxRow .tableBoxColumn{
    max-width:150px;
    min-width:100px;
    padding-top:5px;
    padding-bottom:5px;
}
.tableBoxCSV .tableBoxRow .tableBoxColumn.narrow{
    min-width:40px;
    max-width:40px!important;
}

.tableBoxCSV .tableBoxRow-head .tableBoxColumn{
    padding-top:5px;
    padding-bottom:5px;
}
.tableBoxCSV .tableBoxRow .tableBoxColumn:nth-child(3){
    min-width:200px;
}

.ui.uploadCSVTemplateModal .content > div:nth-child(3){
    width:100%; float:left;
    border:none!important;
    border-radius:0!important;
    padding:0!important;
}
.ui.uploadCSVTemplateModal .content > div .ui.attached.segment{
    width:100%;
    float:left;
    padding:0;
    margin:0;
    border-radius:0;
    border:none;
}
.ui.uploadCSVTemplateModal .field-error{
    margin:15px 0 0;
}
.ui.uploadCSVTemplateModal .content > div:nth-child(3) > div:not(.segment){
    width:100%!important;
    height:auto!important;
    border-radius:2px!important;
    font:400 13px 'Rubik',sans-serif;
    padding:15px 10px!important;
    border: dashed 1.5px #D3D2DE!important;
    background: #F3F6FB!important;
}
.ui.uploadCSVTemplateModal .content > div:nth-child(3) > div:not(.segment) span:first-child{
    background:rgba(255,255,255,.2);
    border:dashed 1px #D3D2DE;
    padding:5px 10px!important;
    display:inline-block;
    font-weight:500;
}
.ui.uploadCSVTemplateModal .content > div:nth-child(3) > div:not(.segment) span:last-child{
    word-break:break-all;
    background:none;
    font-size:12px!important;
    line-height:15px!important;
}