.ui.modal.roleContactModal{
    width:576px;
    max-width:100%;
    top:80px!important;
    padding-left:0!important;
    padding-right:0!important;
    overflow: inherit;
}

.ui.modal.roleContactModal .aligner{
    padding:0 40px;
}

.ui.roleContactModal .inputFormGroup{
    margin-bottom:10px;
}

.ui.roleContactModal .ui.buttonIcon{
    margin:0 0 30px;
}

.ui.roleContactModal > .content{
    padding:0!important;
}

.ui.roleContactModal > .actions{
    padding:0 40px 30px!important;
}

.ui.roleContactModal > .actions .ui.button{
    margin-bottom:0;
}

.scrolledFlex{
    width: 100%; float: left;
    max-height:40vh;
    overflow-y:auto;
}

.flexTwinModal {
    width:100%; float:left;
    display:flex;
    align-items:flex-start;
}
.flexTwinModal .ui.button.roleActionButton{
    position: relative;
    margin:0 8px 18px 0!important;
    align-self:flex-end;
}
.flexTwinModal .ui.button.roleActionButton img{width:18px; min-width:18px;}

.flexTwinModal .inputFormGroup{
    width:45%;
    flex:1;
    margin:0 5% 15px 0;
}
.flexTwinModal .inputFormGroup > b{
    margin:0 0 5px!important;
}
.flexTwinModal .inputFormGroup .ui.search.dropdown>.text{
    max-width:90%;
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
}
.flexTwinModal .inputFormGroup .ui.dropdown .menu{
    max-width:300px;
    min-width:auto;
}
.flexTwinModal .inputFormGroup .ui.dropdown .menu > .item{
    width:100%;
    overflow:hidden;
    white-space:nowrap;
    text-overflow:ellipsis;
}

.ui.button.buttonIcon.addMore{
    padding:0!important;
}



.paginationModal{
    width:100%; float:left;
    display:flex;
    align-items:center;
    justify-content:center;
}

.ui.pagination.menu.paginationModal .item{
    font:400 13px 'Rubik',sans-serif;
    color:#667581;
    outline:0;
    background:#fff;
    border:solid 1px #D3D2DE;
    border-right:none;
    min-width:0!important;
    width:33px;
    height:33px;
    padding:0!important;
    display:flex;
    align-items:center;
    justify-content:center;
}
.ui.pagination.menu.paginationModal .item:before{
    display:none;
}
.ui.pagination.menu.paginationModal .item:first-child{
    border-radius:4px 0 0 4px;
    font-size:14px;
}
.ui.pagination.menu.paginationModal .item:last-child{
    border-radius:0 4px 4px 0;
    border-right:solid 1px #D3D2DE;
    font-size:14px;
}
.ui.pagination.menu.paginationModal .active.item{
    background:#727AFF;
    border-color:#727AFF;
    color:#fff;
}