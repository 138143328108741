.numberText {
    min-width:16px;
    height:16px;
    border-radius:16px;
    background:#F15944;
    font:500 12px/16px 'Rubik',sans-serif;
    text-align:center;
    color:#fff;
    padding:0 3px;
    position:absolute;
    top:2px;
    right:3px;
}
